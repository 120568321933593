//@ts-nocheck
import React, { useState, useEffect } from "react";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
import { useLanguages } from "@/context/LanguageContext";

const AboutUs = () => {
  const { langState } = useLanguages();
  const [shop, setShop] = useState({});
  useEffect(() => {
    const shop_ = readFromLocalStorage(LocalStorageKeys.SHOP);
    setShop(shop_);
  }, []);
  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="About Us" subHeading="About Us" />
      </div>
      <div className="about md:pt-20 pt-10">
        <div className="about-us-block">
          <div className="container">
            <div className="text flex items-center justify-center">
              <div className="content w-full">
                <div className="bg-img m-10">
                  <img
                    src={shop?.aboutUsImageURL}
                    width={2000}
                    height={1000}
                    alt="bg-img"
                    className="w-full rounded-[10px] aspect-[2/1] w-full"
                  />
                </div>
                <div className="heading3 text-center">
                  {shop?.name?.[langState.lang]}
                </div>
                <div
                  className="body1 text-center md:mt-7 mt-5"
                  dangerouslySetInnerHTML={{
                    __html:
                      langState.lang === "ar"
                        ? shop?.aboutUsTextAr
                        : shop?.aboutUsTextEn,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
