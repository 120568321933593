//@ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios, { endpoints } from "@/utils/axios";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";

const ForgotPassword = () => {
  const [errorMessage, setErrorMessage] = useState();
  const initialValues = {
    email: "",
  };

  // Handling form submission
  const handleSubmit = async (values) => {
    setErrorMessage(null);
    try {
      const resp = await axios.post(endpoints.auth.forgotPassword, values);

      if (resp.status === 200) {
        setErrorMessage(
          `An email has been sent to ${values.email}. Please check your inbox (or Junk)`
        );
      } else {
        setErrorMessage("Something went wrong! Please try again.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong! Please try again.");
    } finally {
    }
  };

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb
          heading="Forget your password"
          subHeading="Forget your password"
        />
      </div>
      <div className="forgot-pass md:py-20 py-10">
        <div className="container">
          <div className="content-main flex gap-y-8 max-md:flex-col">
            <div className="left md:w-1/2 w-full lg:pr-[60px] md:pr-[40px] md:border-r border-line">
              <div className="heading4">Reset your password</div>
              <div className="body1 mt-2">
                We will send you an email to reset your password
              </div>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {() => (
                  <Form className="md:mt-7 mt-4">
                    <div className="email ">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email address *"
                        required
                      />
                    </div>
                    {errorMessage && (
                      <label className="cursor-pointer text-red">
                        {errorMessage}
                      </label>
                    )}
                    <div className="block-button md:mt-7 mt-4">
                      <button className="button-main">Submit</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="right md:w-1/2 w-full lg:pl-[60px] md:pl-[40px] flex items-center">
              <div className="text-content">
                <div className="heading4">New Customer</div>
                <div className="mt-2 text-secondary">
                  Be part of our growing family of new customers! Join us today
                  and unlock a world of exclusive benefits, offers, and
                  personalized experiences.
                </div>
                <div className="block-button md:mt-7 mt-4">
                  <Link to={"/register"} className="button-main">
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
