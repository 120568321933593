//@ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as Icon from "@phosphor-icons/react";
import Product from "@/components/Product/Product";
import useLoginPopup from "@/store/useLoginPopup";
import useMenuMobile from "@/store/useMenuMobile";
import { useModalCartContext } from "@/context/ModalCartContext";
import { useModalWishlistContext } from "@/context/ModalWishlistContext";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
import { useUser } from "@/context/UserContext";
import { useCart } from "@/context/CartContext";
import { useLanguages } from "@/context/LanguageContext";

const MenuSloth = () => {
  const { langState, t } = useLanguages();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const { userState, clearUserFromLocalStorage } = useUser();
  const user = userState.user || null;
  const { shoppingCart } = useCart();
  const { openLoginPopup, handleLoginPopup } = useLoginPopup();
  const { openMenuMobile, handleMenuMobile } = useMenuMobile();
  const [openSubNavMobile, setOpenSubNavMobile] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const { openModalCart } = useModalCartContext();
  const { openModalWishlist } = useModalWishlistContext();

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const menu_ = readFromLocalStorage(LocalStorageKeys.MENU);
    setMenu(menu_);
  }, []);

  const [featured, setFeatured] = useState({
    newArrivals: [],
    sales: [],
    bestSellers: [],
  });

  useEffect(() => {
    const featured_ = readFromLocalStorage(LocalStorageKeys.FEATURED);
    setFeatured(featured_);
  }, []);

  const handleOpenSubNavMobile = (index: number) => {
    setOpenSubNavMobile(openSubNavMobile === index ? null : index);
  };

  const [fixedHeader, setFixedHeader] = useState(false);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setFixedHeader(scrollPosition > 0 && scrollPosition < lastScrollPosition);
      setLastScrollPosition(scrollPosition);
    };

    // Gắn sự kiện cuộn khi component được mount
    window.addEventListener("scroll", handleScroll);

    // Hủy sự kiện khi component bị unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  const handleProductClick = (product: string) => {
    navigate(`/shop?product=${product}`);
    handleMenuMobile();
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(menu?.find((x) => x._id === category) || menu[0]);
    // router.push(`/shop?category=${category}`);
    // handleMenuMobile();
  };

  return (
    <>
      <div
        className={`header-menu style-one ${
          fixedHeader ? " fixed" : "relative"
        } bg-white w-full md:h-[74px] h-[72px]`}
        style={{ backgroundColor: "#fef6eb" }}
      >
        <div className="container mx-auto h-full">
          <div className="header-main flex justify-between h-full">
            <div
              className="menu-mobile-icon lg:hidden flex items-center"
              onClick={handleMenuMobile}
            >
              <i className="icon-category text-2xl"></i>
            </div>
            <Link to={"/"} className="flex items-center">
              <div className="heading4">
                <img
                  src={"/images/logo/logo_full.png"}
                  alt="Sloth"
                  className="w-full h-full object-cover"
                  style={{ width: 113, height: 49 }}
                />
              </div>
            </Link>
            <div className="menu-main h-full max-lg:hidden">
              <ul className="flex items-center gap-8 h-full">
                <li className="h-full relative">
                  <Link
                    to="/"
                    className={`text-button-uppercase duration-300 h-full flex items-center justify-center gap-1 
                                            ${
                                              pathname === "/" ||
                                              pathname === "/home"
                                                ? "active"
                                                : ""
                                            }`}
                  >
                    {t("Home")}
                  </Link>
                </li>
                <li className="h-full relative">
                  <Link
                    to="#!"
                    className={`text-button-uppercase duration-300 h-full flex items-center justify-center ${
                      pathname.includes("/shop") ? "active" : ""
                    }`}
                  >
                    {t("Shop")}
                  </Link>
                  <div className="sub-menu py-3 px-5 -left-10 absolute bg-white rounded-b-xl">
                    <ul className="w-full">
                      {menu?.map(
                        (cat: {
                          name: { en: string | undefined };
                          _id: string;
                        }) => (
                          <li key={cat._id}>
                            <Link
                              to={`/shop?category=${cat._id}`}
                              className={`text-secondary duration-300 ${
                                pathname === `/shop?category=${cat._id}`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {cat.name?.[langState?.lang]}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </li>
                <li className="h-full">
                  <Link
                    to="#!"
                    className="text-button-uppercase duration-300 h-full flex items-center justify-center"
                  >
                    {t("Product")}
                  </Link>
                  <div className="mega-menu absolute top-[74px] left-0 bg-white w-screen">
                    <div className="container">
                      <div className="flex justify-between py-8">
                        <div className="nav-link basis-2/3 flex justify-between xl:pr-14 pr-5">
                          <div className="nav-item">
                            <div className="text-button-uppercase pb-2">
                              {t("Best Sellers")}
                            </div>
                            <ul>
                              {featured?.bestSellers
                                ?.slice(0, 6)
                                ?.map((prod) => (
                                  <li key={prod._id}>
                                    <Link
                                      to={`/shop=${prod._id}`}
                                      className={`text-secondary duration-300 ${
                                        pathname.includes(prod._id)
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {prod.name?.[langState?.lang]}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="nav-item">
                            <div className="text-button-uppercase pb-2">
                              {t("On Sale")}
                            </div>
                            <ul>
                              {featured?.sales?.slice(0, 6)?.map((prod) => (
                                <li key={prod._id}>
                                  <Link
                                    to={`/shop?product=${prod._id}`}
                                    className={`text-secondary duration-300 ${
                                      pathname.includes(prod._id)
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    {prod.name?.[langState?.lang]}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="nav-item">
                            <div className="text-button-uppercase pb-2">
                              {t("New Arrivals")}
                            </div>
                            <ul>
                              {featured?.newArrivals
                                ?.slice(0, 6)
                                ?.map((prod) => (
                                  <li key={prod._id}>
                                    <Link
                                      to={`/shop?product=${prod._id}`}
                                      className={`text-secondary duration-300 ${
                                        pathname.includes(prod._id)
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {prod.name?.[langState?.lang]}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <div className="recent-product pl-2.5 basis-1/3">
                          {/* <div className="text-button-uppercase pb-2">Recent Products</div> */}
                          <div className="list-product hide-product-sold  grid grid-cols-2 gap-5 mt-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="h-full relative">
                  <Link
                    to="#!"
                    className="text-button-uppercase duration-300 h-full flex items-center justify-center"
                  >
                    {t("Support")}
                  </Link>
                  <div className="sub-menu py-3 px-5 -left-10 absolute bg-white rounded-b-xl">
                    <ul className="w-full">
                      <li>
                        <Link
                          to="/about"
                          className={`text-secondary duration-300 ${
                            pathname === "/about" ? "active" : ""
                          }`}
                        >
                          {t("About Us")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          className={`text-secondary duration-300 ${
                            pathname === "/contact" ? "active" : ""
                          }`}
                        >
                          {t("Contact Us")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shipping"
                          className={`text-secondary duration-300 ${
                            pathname === "/shipping" ? "active" : ""
                          }`}
                        >
                          {t("Shipping & Delivery")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/exchange"
                          className={`text-secondary duration-300 ${
                            pathname === "/exchange" ? "active" : ""
                          }`}
                        >
                          {t("Exchange & Return")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/faqs"
                          className={`text-secondary duration-300 ${
                            pathname === "/faqs" ? "active" : ""
                          }`}
                        >
                          {t("FAQs")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/wholesale"
                          className={`text-secondary duration-300 ${
                            pathname === "/wholesale" ? "active" : ""
                          }`}
                        >
                          {t("Wholesale/Become a distributor")}
                        </Link>
                      </li>
                      {/* <li>
                                                <Link to="/customer-feedbacks" className={`text-secondary duration-300 ${pathname === '/customer-feedbacks' ? 'active' : ''}`}>
                                                    Customer Feedbacks
                                                </Link>
                                            </li> */}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="right flex gap-12">
              <div className="list-action flex items-center gap-4">
                <div
                  className="max-md:hidden wishlist-icon flex items-center cursor-pointer"
                  onClick={openModalWishlist}
                >
                  <img
                    src={"/images/icons/whishlist-empty-large.png"}
                    width={30}
                    height={30}
                    alt="Cart"
                    className="w-full h-full object-cover"
                    style={{ width: 40, height: 40, objectFit: "contain" }}
                  />
                </div>
                <div
                  className=" cart-icon flex items-center relative cursor-pointer"
                  onClick={openModalCart}
                >
                  <img
                    src={"/images/icons/cart-empty-large.png"}
                    // width={40}
                    // height={20}
                    alt="Cart"
                    className="w-full h-full object-cover"
                    style={{ width: 45, height: 36, objectFit: "contain" }}
                  />
                  {shoppingCart?.length ? (
                    <span className="quantity cart-quantity absolute -right-0 -top-0 text-xs text-white bg-black w-4 h-4 flex items-center justify-center rounded-full">
                      {shoppingCart?.length || 0}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="user-icon flex items-center justify-center cursor-pointer">
                  <img
                    src={"/images/sloth_user.png"}
                    width={30}
                    height={30}
                    alt={user?.name ?? "user"}
                    className="w-full h-full object-cover"
                    onClick={handleLoginPopup}
                    style={{ width: 30, height: 30 }}
                  />
                  {!!user ? (
                    <div className="text-secondary text-center mt-3 pb-4">
                      {user?.name?.split(" ")?.[0]}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`login-popup absolute top-[74px] w-[320px] p-7 rounded-xl bg-white box-shadow-small 
                                            ${openLoginPopup ? "open" : ""}`}
                  >
                    {!!user ? (
                      <>
                        <div className="text-secondary text-center mt-3 pb-4">
                          {t(" Welcome")} {user?.name}
                          <button
                            onClick={() => {
                              clearUserFromLocalStorage();
                            }}
                            className="button-main w-full text-center"
                          >
                            {t("Logout")}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Link
                          to={"/login"}
                          className="button-main w-full text-center"
                        >
                          {t("Login")}
                        </Link>
                        <div className="text-secondary text-center mt-3 pb-4">
                          {t("Don’t have an account?")}
                          <Link
                            to={"/register"}
                            className="text-black pl-1 hover:underline font-bold"
                          >
                            {t("Register")}
                          </Link>
                        </div>
                        <div className="bottom pt-4 border-t border-line"></div>
                        <Link to={"/contact"} className="body1 hover:underline">
                          {t("Support")}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="menu-mobile" className={`${openMenuMobile ? "open" : ""}`}>
        <div className="menu-container bg-white h-full">
          <div className="container h-full">
            <div className="menu-main h-full overflow-hidden">
              <div className="heading py-2 relative flex items-center justify-center">
                <div
                  className="close-menu-mobile-btn absolute left-0 top-1/2 -translate-y-1/2 w-6 h-6 rounded-full bg-surface flex items-center justify-center"
                  onClick={handleMenuMobile}
                >
                  <Icon.X size={14} />
                </div>
                <Link
                  to={"/"}
                  className="logo text-3xl font-semibold text-center"
                >
                  <img
                    src={"/images/logo/logo_full.png"}
                    width={100}
                    height={50}
                    alt="Sloth"
                    className="w-full h-full object-contain"
                    style={{ width: 113, height: 50 }}
                  />
                </Link>
              </div>
              <div className="form-search relative mt-2"></div>
              <div className="list-nav mt-6">
                <ul>
                  <li
                    className={`${openSubNavMobile === 1 ? "open" : ""}`}
                    onClick={() => handleOpenSubNavMobile(1)}
                  >
                    <a
                      to={"/"}
                      className={`text-xl font-semibold flex items-center justify-between`}
                    >
                      {t("Home")}
                    </a>
                  </li>
                  <li
                    className={`${openSubNavMobile === 2 ? "open" : ""}`}
                    onClick={() => handleOpenSubNavMobile(2)}
                  >
                    <a
                      to={"#!"}
                      className="text-xl font-semibold flex items-center justify-between mt-5"
                    >
                      {t("Shop")}
                      <span className="text-right">
                        <Icon.CaretRight size={20} />
                      </span>
                    </a>
                    <div className="sub-nav-mobile">
                      <div
                        className="back-btn flex items-center gap-3"
                        onClick={() => handleOpenSubNavMobile(2)}
                      >
                        <Icon.CaretLeft />
                        {t("Back")}
                      </div>
                      <div className="list-nav-item w-full pt-3 pb-12">
                        <div className="nav-link grid grid-cols-1 gap-5 gap-y-6">
                          {menu?.map((category) => (
                            <div
                              key={category._id}
                              className="nav-item flex items-center justify-between"
                              onClick={() => handleCategoryClick(category._id)}
                            >
                              <div className="text-button-uppercase pb-1 ">
                                {category?.name?.[langState?.lang]}
                              </div>
                              <Icon.CaretRight />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>

                  <li
                    className={`mt-4 ${openSubNavMobile === 7 ? "open" : ""}`}
                    onClick={() => handleOpenSubNavMobile(7)}
                  >
                    {menu?.map((category) => (
                      <div
                        key={category._id}
                        className="nav-item flex items-center justify-between"
                        onClick={() => handleCategoryClick(category._id)}
                      >
                        <div className="text-button-uppercase pb-1 mt-1">
                          {category?.name?.[langState?.lang]}
                        </div>
                        <Icon.CaretRight />
                      </div>
                    ))}
                    <div className="sub-nav-mobile h-full">
                      <div
                        className="back-btn flex items-center gap-3"
                        onClick={() => handleOpenSubNavMobile(7)}
                      >
                        <Icon.CaretLeft />
                        {t("Back")}
                      </div>
                      {selectedCategory?.products?.map((product) => (
                        <div
                          key={product._id}
                          className="nav-item flex items-center justify-between mt-2"
                          onClick={() => handleProductClick(product._id)}
                        >
                          <div className="text-button-uppercase pb-1 ">
                            {product?.name?.[langState?.lang]}
                          </div>
                          <Icon.CaretRight />
                        </div>
                      ))}
                    </div>
                  </li>
                  <li
                    className={`${openSubNavMobile === 4 ? "open" : ""}`}
                    onClick={() => handleOpenSubNavMobile(4)}
                  >
                    <a
                      to={"#!"}
                      className="text-xl font-semibold flex items-center justify-between mt-5"
                    >
                      {t("Product")}
                      <span className="text-right">
                        <Icon.CaretRight size={20} />
                      </span>
                    </a>
                    <div className="sub-nav-mobile">
                      <div
                        className="back-btn flex items-center gap-3"
                        onClick={() => handleOpenSubNavMobile(4)}
                      >
                        <Icon.CaretLeft />
                        {t("Back")}
                      </div>
                      <div className="list-nav-item w-full pt-3 pb-12">
                        <div className="">
                          <div className="nav-link grid grid-cols-2 gap-5 gap-y-6 justify-between">
                            <div className="nav-item">
                              <div className="text-button-uppercase pb-1">
                                {t("Best Sellers")}
                              </div>
                              <ul>
                                {featured?.bestSellers
                                  ?.slice(0, 4)
                                  ?.map((product) => (
                                    <li key={product._id}>
                                      <div
                                        onClick={() =>
                                          handleCategoryClick(product._id)
                                        }
                                        className={`link text-secondary duration-300 cursor-pointer`}
                                      >
                                        {product.name?.[langState?.lang]}
                                      </div>
                                    </li>
                                  ))}

                                <li>
                                  <div
                                    onClick={() => handleCategoryClick("all")}
                                    className={`link text-secondary duration-300 cursor-pointer view-all-btn`}
                                  >
                                    {t("View All")}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="nav-item">
                              <div className="text-button-uppercase pb-1">
                                {t("Sales")}
                              </div>
                              <ul>
                                {featured?.sales
                                  ?.slice(0, 4)
                                  ?.map((product) => (
                                    <li key={product._id}>
                                      <div
                                        onClick={() =>
                                          handleCategoryClick(product._id)
                                        }
                                        className={`link text-secondary duration-300 cursor-pointer`}
                                      >
                                        {product.name?.[langState?.lang]}
                                      </div>
                                    </li>
                                  ))}

                                <li>
                                  <div
                                    onClick={() => handleCategoryClick("all")}
                                    className={`link text-secondary duration-300 cursor-pointer view-all-btn`}
                                  >
                                    {t("View All")}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="nav-item">
                              <div className="text-button-uppercase pb-1">
                                {t("New Arrivals")}
                              </div>
                              <ul>
                                {featured?.newArrivals
                                  ?.slice(0, 4)
                                  ?.map((product) => (
                                    <li key={product._id}>
                                      <div
                                        onClick={() =>
                                          handleCategoryClick(product._id)
                                        }
                                        className={`link text-secondary duration-300 cursor-pointer`}
                                      >
                                        {product.name?.[langState?.lang]}
                                      </div>
                                    </li>
                                  ))}

                                <li>
                                  <div
                                    onClick={() => handleCategoryClick("all")}
                                    className={`link text-secondary duration-300 cursor-pointer view-all-btn`}
                                  >
                                    {t("View All")}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="recent-product pt-4">
                            <div className="text-button-uppercase pb-1">
                              {t("Recommended")}
                            </div>
                            <div className="list-product hide-product-sold  grid grid-cols-2 gap-5 mt-3">
                              {featured?.bestSellers
                                ?.slice(0, 2)
                                .map((prd, index) => (
                                  <Product key={index} data={prd} type="grid" />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li
                    className={`${openSubNavMobile === 6 ? "open" : ""}`}
                    onClick={() => handleOpenSubNavMobile(6)}
                  >
                    <a
                      to={"#!"}
                      className="text-xl font-semibold flex items-center justify-between mt-5"
                    >
                      {t("Support")}
                      <span className="text-right">
                        <Icon.CaretRight size={20} />
                      </span>
                    </a>
                    <div className="sub-nav-mobile">
                      <div
                        className="back-btn flex items-center gap-3"
                        onClick={() => handleOpenSubNavMobile(6)}
                      >
                        <Icon.CaretLeft />
                        {t("Back")}
                      </div>
                      <div className="list-nav-item w-full pt-2 pb-6">
                        <ul className="w-full">
                          <li>
                            <Link
                              to="/about"
                              className={`text-secondary duration-300 ${
                                pathname === "/about" ? "active" : ""
                              }`}
                            >
                              {t("About Us")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/contact"
                              className={`text-secondary duration-300 ${
                                pathname === "/contact" ? "active" : ""
                              }`}
                            >
                              {t("Contact Us")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/shipping"
                              className={`text-secondary duration-300 ${
                                pathname === "/shipping" ? "active" : ""
                              }`}
                            >
                              {t("Shipping & Delivery")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/exchange"
                              className={`text-secondary duration-300 ${
                                pathname === "/exchange" ? "active" : ""
                              }`}
                            >
                              {t("Exchange & Return")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/faqs"
                              className={`text-secondary duration-300 ${
                                pathname === "/faqs" ? "active" : ""
                              }`}
                            >
                              {t("FAQs")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/wholesale"
                              className={`text-secondary duration-300 ${
                                pathname === "/wholesale" ? "active" : ""
                              }`}
                            >
                              {t("Wholesale/Become a distributor")}
                            </Link>
                          </li>
                          {/* <li>
                                                        <Link to="/customer-feedbacks" className={`text-secondary duration-300 ${pathname === '/customer-feedbacks' ? 'active' : ''}`}>
                                                            Customer Feedbacks
                                                        </Link>
                                                    </li> */}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuSloth;
