import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/bundle";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
import "react-loading-skeleton/dist/skeleton.css";
import { useLanguages } from "@/context/LanguageContext";

interface Props {
  props: string;
}

const Collection: React.FC<Props> = ({ props }) => {
  const { langState, t } = useLanguages();
  const navigate = useNavigate();

  const [menu, setMenu] = useState([]);
  useEffect(() => {
    const menu_ = readFromLocalStorage(LocalStorageKeys.MENU);
    //@ts-ignore
    setMenu(menu_);
  }, []);

  const handleTypeClick = (id: string) => {
    navigate(`/shop?category=${id}`);
  };

  return (
    <>
      <div className={`collection-block ${props}`} style={{ marginTop: 40 }}>
        <div
          className="heading flex flex-col items-center text-center"
          style={{ marginBottom: 20 }}
        >
          <div className="heading3">{t("Collections")}</div>
        </div>
        <div className="list-collection section-swiper-navigation sm:px-5 px-4">
          <Swiper
            spaceBetween={4}
            slidesPerView={2}
            navigation
            loop={true}
            modules={[Navigation, Autoplay]}
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            className="h-full"
          >
            {menu?.map(
              (category: {
                _id: string;
                imageURL: string;
                name: { en: string };
              }) => (
                <SwiperSlide key={category?._id}>
                  <>
                    <div
                      className="collection-item block relative rounded-2xl overflow-hidden cursor-pointer"
                      onClick={() => handleTypeClick(category._id)}
                    >
                      <div className="bg-img">
                        <img
                          src={category.imageURL}
                          width={1000}
                          height={562}
                          //@ts-ignore
                          alt={category.name?.[langState?.lang]}
                        />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="collection-name heading5 text-center sm:bottom-8 bottom-4 lg:w-[200px] md:w-[160px] w-[100px] md:py-3 py-1.5 bg-white rounded-xl duration-500"
                    >
                      {/* @ts-ignore */}
                      {category.name?.[langState?.lang]}
                    </div>
                  </>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Collection;
