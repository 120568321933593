//@ts-nocheck
import React, { useEffect, useState } from "react";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import { useSearchParams } from "react-router-dom";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import { useCart } from "@/context/CartContext";
import { usePrices } from "@/context/PricesContext";
import { useUser } from "@/context/UserContext";
import { useKWCities } from "@/api/kuwaitCities";
import { useLanguages } from "@/context/LanguageContext";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
import Select from "react-select";
import { useCities } from "@/api/deliveryFees";
import TabbyCheckout from "@/components/Tabby/TabbyCheckout";

const Checkout = () => {
  const [searchParams] = useSearchParams();
  const errorReturn = searchParams.get("error");
  const cancelReturn = searchParams.get("cancel");
  const { langState, t } = useLanguages();
  const {
    shoppingCart,
    discount,
    currency,
    notes,
    cartWeight,
    cartTotal,
    submitOrder,
  } = useCart();
  const {
    formatPrice,
    // calaculateTotalDeliveryRate,
    calculateTotalDeliveryRateByCountry,
  } = usePrices();
  const { userState } = useUser();

  const [shop, setShop] = useState({});
  useEffect(() => {
    const shop_ = readFromLocalStorage(LocalStorageKeys.SHOP);
    setShop(shop_);
  }, []);

  const preOrders = shoppingCart
    ?.filter((x) => x.isPreOrder)
    ?.sort((a, b) => (a.availabilityDate > b.availabilityDate ? -1 : 1));

  const user = userState.user || null;
  const { kwCities } = useKWCities();
  const [userInfo, setUserInfo] = useState({
    name: user?.name,
    phoneNumber: user?.phoneNumber,
    email: user?.email,
  });
  useEffect(() => {
    setUserInfo({
      name: user?.name,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
    });
  }, [user]);

  const [countryid, setCountryid] = useState("KUWAIT");
  const [countryCode, setCountryCode] = useState("KW");
  const [hasTabby, setHasTabby] = useState(true);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const { cities } = useCities();
  const [paymentMethod, setPaymentMethod] = useState("UPAY");
  useEffect(() => {
    setCountriesList(cities);
  }, [cities]);
  // useEffect(() => {
  //   GetCountries().then((result) => {
  //     setCountriesList(result);
  //   });
  // }, []);

  const [address, setAddress] = useState({
    country: "KUWAIT",
    city: "",
    block: "",
    addressLine1: "",
    addressLine2: "",
    postCode: "",
    notes: "",
    countryCode: "KW",
  });

  // let deliveryFees =
  //   address?.state && address?.city ? address?.state?.deliveryFees : 0;
  // let deliveryFees = calaculateTotalDeliveryRate(
  //   cartWeight,
  //   cities.find(x =>
  //     x.country?.toLowerCase()?.includes(address?.country?.toLowerCase()),
  //   ),
  // );
  const [deliveryFees, setDeliveryFees] = useState(0);
  useEffect(() => {
    // console.log("DELIVERY", {
    //   countryid,
    //   cartWeight,
    //   cartTotal,
    //   postCode: address?.postCode,
    //   countryCode,
    //   cityid,
    //   kwCities,
    // });
    const calcDelFees = async () => {
      let delFees =
        countryid === "KUWAIT"
          ? shop?.freeDeliveryInKuwait
            ? 0
            : kwCities?.find((x) => x.id == cityid)?.deliveryFees
          : calculateTotalDeliveryRateByCountry(
              cartWeight,
              countriesList.find((x) => x.country === countryid)
            );
      // : await calaculateTotalDeliveryRate(
      //     countryCode,
      //     address?.postCode,
      //     cartWeight,
      //     cartTotal,
      //     shoppingCart,
      //     address?.city
      //   );

      setDeliveryFees(delFees);
    };
    if (countriesList?.length) {
      calcDelFees();
    }
  }, [
    countryid,
    cartWeight,
    // cartTotal,
    countriesList,
    // address?.postCode,
    // countryCode,
    cityid,
    kwCities,
    // address?.city,
  ]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [redirectMessage, setRedirectMessage] = useState(null);

  useEffect(() => {
    if (errorReturn) {
      setErrorMessage(t("Tabby Error Not Available"));
    }
    if (cancelReturn) {
      setErrorMessage(t("Tabby Cancelation"));
    }
  }, [errorReturn, cancelReturn]);
  const isValidOrder =
    shoppingCart?.length > 0 &&
    userInfo?.phoneNumber &&
    address?.country &&
    address?.country != "" &&
    address?.countryCode &&
    address?.countryCode != "" &&
    address?.city &&
    address?.city != "" &&
    (countryid === "KUWAIT"
      ? address?.block && address?.block != ""
      : address?.postCode && address?.postCode != "") &&
    address?.addressLine1 &&
    address?.addressLine1 != ""; // &&
  // deliveryFees > 0;

  const handlePay = async () => {
    setErrorMessage(null);
    setRedirectMessage(null);
    if (!isValidOrder) {
      setErrorMessage("Please fill all the required information");
      return;
    }
    try {
      let resp = await submitOrder({
        address,
        userInfo,
        deliveryFees,
        isPreOrder: preOrders?.length > 0,
        availabilityDate: preOrders?.[0]?.availabilityDate,
        paymentMethod,
        lang: langState?.lang,
      });
      if (!resp) {
        setErrorMessage("An error occured, Please try again");
      } else {
        setRedirectMessage(
          "Please Wait. You will be redirected to the payment page"
        );
      }
    } catch (error) {
      if (paymentMethod === "TABBY") {
        if (error === "not_available") {
          setErrorMessage("Tabby Error Not Available");
        } else if (error === "order_amount_too_high") {
          setErrorMessage("Tabby Error Amount Too High");
        } else if (error === "order_amount_too_low") {
          setErrorMessage("Tabby Error Amount Too Low");
        } else {
          setErrorMessage("An error occured, Please try again");
        }
      } else {
        setErrorMessage("An error occured, Please try again");
      }
    }
  };
  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="Checkout" subHeading="Checkout" />
      </div>
      <div className="cart-block md:py-20 py-10">
        <div className="container">
          <div className="content-main flex justify-between flex-col md:flex-row gap-y-8">
            <div className=" w-full  md:w-1/2 px-4">
              {/* {!user && (
                <>
                  <div className='login bg-surface py-3 px-4 flex justify-between rounded-lg'>
                    <div className='left flex items-center'>
                      <span className='text-on-surface-variant1 pr-4'>
                        Already have an account?{" "}
                      </span>
                      <span className='text-button text-on-surface hover-underline cursor-pointer'>
                        Login
                      </span>
                    </div>
                    <div className='right'>
                      <i className='ph ph-caret-down fs-20 d-block cursor-pointer'></i>
                    </div>
                  </div>
                  <div className='form-login-block mt-3'>
                    <form className='p-5 border border-line rounded-lg'>
                      <div className='grid sm:grid-cols-2 gap-5'>
                        <div className='email '>
                          <input
                            className='border-line px-4 pt-3 pb-3 w-full rounded-lg'
                            id='username'
                            type='email'
                            placeholder='Username or email'
                            required
                          />
                        </div>
                        <div className='pass '>
                          <input
                            className='border-line px-4 pt-3 pb-3 w-full rounded-lg'
                            id='password'
                            type='password'
                            placeholder='Password'
                            required
                          />
                        </div>
                      </div>
                      <div className='block-button mt-3'>
                        <button className='button-main button-blue-hover'>
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )} */}

              <div className="information mt-5">
                <div className="heading5">{t("Information")}</div>
                <div className="form-checkout mt-5">
                  <form>
                    <div className="grid sm:grid-cols-2 gap-4 gap-y-5 flex-wrap">
                      <div className="">
                        <input
                          className="border-line px-4 py-3 w-full rounded-lg"
                          id="name"
                          type="text"
                          placeholder="Name *"
                          required
                          value={userInfo?.name}
                          onChange={(ev) =>
                            setUserInfo({ ...userInfo, name: ev.target.value })
                          }
                        />
                      </div>

                      <div className="">
                        <input
                          className="border-line px-4 py-3 w-full rounded-lg"
                          id="phoneNumber"
                          type="text"
                          placeholder="Phone Numbers *"
                          required
                          value={userInfo?.phoneNumber}
                          onChange={(ev) =>
                            setUserInfo({
                              ...userInfo,
                              phoneNumber: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="">
                        <input
                          className="border-line px-4 py-3 w-full rounded-lg"
                          id="email"
                          type="email"
                          placeholder="Email Address *"
                          required
                          value={userInfo?.email}
                          onChange={(ev) =>
                            setUserInfo({ ...userInfo, email: ev.target.value })
                          }
                        />
                      </div>
                      <div></div>

                      <div className="heading5">{t("Delivery Address")}</div>
                      <div className="col-span-full select-block">
                        {/* <select
                          className='border border-line px-4 py-3 w-full rounded-lg'
                          id='country'
                          name='country'
                          value={countryid}
                          onChange={ev => {
                            let country = countriesList.find(
                              x => x.id == ev.target.value,
                            );

                            setCountryid(country?.id);
                            setCountryCode(country?.iso2);
                            setAddress({
                              ...address,
                              country: country?.name,
                              countryCode: country?.iso2,

                              city: "",
                            });
                          }}
                        >
                          {countriesList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        
                        </select>*/}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={countriesList
                            .map((country) => ({
                              value: country.country,
                              label: country.country,
                            }))
                            .find((c) => c.value === countryid)}
                          value={countriesList
                            .map((country) => ({
                              value: country.country,
                              label: country.country,
                            }))
                            .find((c) => c.value === countryid)}
                          isSearchable={true}
                          name="country"
                          options={countriesList.map((country) => ({
                            value: country.country,
                            label: country.country,
                          }))}
                          onChange={(option) => {
                            const selectedCountry = countriesList.find(
                              (x) => x.country === option.value
                            );
                            console.log("SELECTED", selectedCountry);
                            setCountryid(option.value);
                            setCountryCode(option.value);
                            setHasTabby(selectedCountry?.hasTabby);
                            if (!setHasTabby?.hasTabby) {
                              setPaymentMethod("UPAY");
                            }
                            setAddress({
                              ...address,
                              country: option.value,
                              countryCode: option.value,
                              city: "",
                            });
                          }}
                        />

                        {/* <Icon.CaretDown className='arrow-down' /> */}
                      </div>

                      {countryid === "KUWAIT" ? (
                        <div className="select-block">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={kwCities.find((c) => c.id === cityid)}
                            isSearchable={true}
                            name="city"
                            options={kwCities.map((city) => ({
                              value: city.id,
                              label: city.name[langState?.lang] || city.name.en,
                            }))}
                            onChange={(option) => {
                              setCityid(option.value);
                              setAddress({
                                ...address,
                                city: option.label,
                              });
                            }}
                          />

                          {/* <select
                            className='border border-line px-4 py-3 w-full rounded-lg'
                            id='city'
                            name='city'
                            onChange={ev => {
                              setCityid(ev.target.value);
                              setAddress({
                                ...address,
                                city: kwCities.find(
                                  x => x.id == ev.target.value,
                                )?.name?.en,
                              });
                            }}
                          >
                            <option value='default'>Choose State *</option>
                            {kwCities?.map(city => (
                              <option value={city?.id}>
                                {city?.name?.[langState?.lang]}
                              </option>
                            ))}
                          </select>
                          <Icon.CaretDown className='arrow-down' /> */}
                        </div>
                      ) : (
                        <div className="">
                          <input
                            className="border-line px-4 py-3 w-full rounded-lg"
                            id="city"
                            type="text"
                            placeholder={t("City*")}
                            required
                            value={address?.city}
                            onChange={(ev) =>
                              setAddress({
                                ...address,
                                city: ev.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                      {/* <div className=''>
                        <input
                          className='border-line px-4 py-3 w-full rounded-lg'
                          id='street'
                          type='text'
                          placeholder={t("Street,... *")}
                          required
                          value={address?.street}
                          onChange={ev =>
                            setAddress({
                              ...address,
                              street: ev.target.value,
                            })
                          }
                        />
                      </div> */}
                      {countryid === "KUWAIT" ? (
                        <div className="">
                          <input
                            className="border-line px-4 py-3 w-full rounded-lg"
                            id="block"
                            type="text"
                            required={countryid === "KUWAIT"}
                            placeholder={`${t("Block")}${
                              countryid === "KUWAIT" ? "*" : ""
                            }`}
                            value={address?.block}
                            onChange={(ev) =>
                              setAddress({
                                ...address,
                                block: ev.target.value,
                              })
                            }
                          />
                        </div>
                      ) : (
                        <div className="">
                          <input
                            className="border-line px-4 py-3 w-full rounded-lg"
                            id="postCode"
                            type="text"
                            required={countryid !== "KUWAIT"}
                            placeholder={`${t("Postal Code")}${
                              countryid !== "KUWAIT" ? "*" : ""
                            }`}
                            value={address?.postCode}
                            onChange={(ev) =>
                              setAddress({
                                ...address,
                                postCode: ev.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                      <div className="col-span-full">
                        <input
                          className="border-line px-4 py-3 w-full rounded-lg"
                          id="addressLine1"
                          type="text"
                          placeholder={t(
                            "Address Line (Street, Appartment, ...) *"
                          )}
                          required
                          value={address?.addressLine1}
                          onChange={(ev) =>
                            setAddress({
                              ...address,
                              addressLine1: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-span-full">
                        <input
                          className="border-line px-4 py-3 w-full rounded-lg"
                          id="addressLine2"
                          type="text"
                          placeholder={t("Additional info (Optional)")}
                          value={address?.addressLine2}
                          onChange={(ev) =>
                            setAddress({
                              ...address,
                              addressLine2: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-span-full">
                        <textarea
                          className="border border-line px-4 py-3 w-full rounded-lg"
                          id="notes"
                          name="notes"
                          placeholder={t("Write note...")}
                          value={address?.notes}
                          onChange={(ev) =>
                            setAddress({
                              ...address,
                              notes: ev.target.value,
                            })
                          }
                        ></textarea>
                      </div>

                      {/* <div className='heading5'>Delivery Address</div>
                      <div className='col-span-full select-block'>
                        <select
                          className='border border-line px-4 py-3 w-full rounded-lg'
                          id='country'
                          name='country'
                          defaultValue={"Kuwait"}
                        >
                          <option value='Kuwait' disabled>
                            Kuwait
                          </option>
                        </select>
                        <Icon.CaretDown className='arrow-down' />
                      </div>
                      <div className='select-block'>
                        <select
                          className='border border-line px-4 py-3 w-full rounded-lg'
                          id='state'
                          name='state'
                          onChange={ev => {
                            setAddress({
                              ...address,
                              state: cities.find(x => x.id == ev.target.value),
                              city: "",
                            });
                          }}
                        >
                          <option value='default'>Choose State *</option>
                          {cities?.map(state => (
                            <option value={state?.id}>
                              {state?.name?.[langState?.lang]}
                            </option>
                          ))}
                        </select>
                        <Icon.CaretDown className='arrow-down' />
                      </div>

                      <div className='select-block'>
                        <select
                          className='border border-line px-4 py-3 w-full rounded-lg'
                          id='state'
                          name='state'
                          onChange={ev =>
                            setAddress({
                              ...address,
                              city: address?.state?.cities?.find(
                                x => x.block === ev.target.value,
                              ),
                            })
                          }
                        >
                          <option value='default'>Choose City * </option>
                          {address?.state?.cities?.map(city => (
                            <option value={city?.block}>
                              Block {city?.block}
                            </option>
                          ))}
                        </select>
                        <Icon.CaretDown className='arrow-down' />
                      </div>
                      <div className=''>
                        <input
                          className='border-line px-4 py-3 w-full rounded-lg'
                          id='street'
                          type='text'
                          placeholder='Street,... *'
                          required
                          value={address?.street}
                          onChange={ev =>
                            setAddress({
                              ...address,
                              street: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className=''>
                        <input
                          className='border-line px-4 py-3 w-full rounded-lg'
                          id='postCode'
                          type='text'
                          required
                          placeholder='Postal Code*'
                          value={address?.postCode}
                          onChange={ev =>
                            setAddress({
                              ...address,
                              postCode: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-span-full'>
                        <input
                          className='border-line px-4 py-3 w-full rounded-lg'
                          id='addressLine1'
                          type='text'
                          placeholder='Address Line (Appartment, ...) *'
                          required
                          value={address?.addressLine1}
                          onChange={ev =>
                            setAddress({
                              ...address,
                              addressLine1: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-span-full'>
                        <input
                          className='border-line px-4 py-3 w-full rounded-lg'
                          id='addressLine2'
                          type='text'
                          placeholder='Additional info (Optional)'
                          value={address?.addressLine2}
                          onChange={ev =>
                            setAddress({
                              ...address,
                              addressLine2: ev.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-span-full'>
                        <textarea
                          className='border border-line px-4 py-3 w-full rounded-lg'
                          id='notes'
                          name='notes'
                          placeholder='Write note...'
                          value={address?.notes}
                          onChange={ev =>
                            setAddress({
                              ...address,
                              notes: ev.target.value,
                            })
                          }
                        ></textarea>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className=" w-full md:w-1/2 px-4">
              <div className="checkout-block">
                <div className="heading5 pb-3">{t("Your Order")}</div>
                <div className="text-button pb-3">
                  {t("Your Order will be available around")}
                </div>
                <div className="heading5 pb-3">
                  {preOrders[0]?.availabilityDate?.split("T")?.[0]}
                </div>
                <div className="list-product-checkout">
                  {shoppingCart?.length < 1 ? (
                    <p className="text-button pt-3">
                      {t("No product in cart")}
                    </p>
                  ) : (
                    shoppingCart?.map((product) => {
                      const productPrice =
                        (product.salePrice || product?.price) +
                        ((product.color?.additionalPrice || 0) +
                          (product.size?.additionalPrice || 0) +
                          (product?.variations || [])?.reduce(
                            (acc, curr) =>
                              acc + curr?.value ? curr.additionalPrice : 0,
                            0
                          ));
                      return (
                        <div key={product.id}>
                          <div className="item flex items-center justify-between w-full pb-5 border-b border-line gap-6 mt-5">
                            <div className="bg-img w-[100px] aspect-square flex-shrink-0 rounded-lg overflow-hidden">
                              <img
                                src={
                                  product.variationImageURL ?? product.imageURL
                                }
                                width={300}
                                height={300}
                                alt="img"
                                className="w-full h-full"
                              />
                            </div>
                            <div className="flex items-center justify-between w-full">
                              <div>
                                <div className="name text-title">
                                  {`${product.name?.[langState?.lang]}`}
                                </div>
                                <div className="caption1 text-secondary mt-2">
                                  <div style={{ display: "flex" }}>
                                    <div className="flex items-center text-secondary2 capitalize">
                                      {`${
                                        product?.isPreOrder
                                          ? `${t("Around")}: ${
                                              product?.availabilityDate?.split(
                                                "T"
                                              )?.[0]
                                            }`
                                          : ""
                                      }`}
                                    </div>
                                  </div>
                                  <span className="size capitalize">
                                    {`${
                                      product.size?.name?.[langState?.lang] ??
                                      ""
                                    }${
                                      product.size && product.color ? " / " : ""
                                    }${
                                      product.color?.name?.[langState?.lang] ??
                                      ""
                                    }`}
                                  </span>

                                  <span className="color capitalize">
                                    {product?.variations?.map((vari) => (
                                      <div className="flex items-center text-secondary2 capitalize">
                                        {`${vari?.textValue || vari?.value}`}
                                      </div>
                                    ))}
                                  </span>
                                </div>
                              </div>
                              <div className="text-title">
                                <span className="quantity">
                                  {product.quantityPurchase}
                                </span>
                                <span className="px-1">x</span>
                                <span>
                                  {formatPrice(
                                    productPrice,
                                    product?.currency || "KD"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>

                <div className="discount-block py-5 flex justify-between border-b border-line">
                  <div className="heading6">{t("Subtotal")}</div>
                  <div className="heading6">
                    <span className="discount">
                      {formatPrice(cartTotal, currency)}
                    </span>
                  </div>
                </div>

                <div className="ship-block py-5 flex justify-between border-b border-line">
                  <div className="text-title">{t("Shipping")}</div>
                  <div className="text-title">
                    {address?.country
                      ? countryid === "KUWAIT" && shop?.freeDeliveryInKuwait
                        ? "Free"
                        : formatPrice(deliveryFees, currency)
                      : t("Please add your address")}
                  </div>
                </div>
                {!!discount && (
                  <div className="discount-block py-5 flex justify-between border-b border-line">
                    <div className="text-title">{t("Discounts")}</div>
                    <div className="text-title">
                      <span className="discount">
                        {formatPrice(discount, currency)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="total-cart-block pt-5 flex justify-between">
                  <div className="heading5">{t("Total")}</div>
                  <div className="heading5 total-cart">
                    {formatPrice(cartTotal + discount + deliveryFees, currency)}
                  </div>
                </div>
              </div>

              <div className="block-button md:mt-10 mt-6">
                {!!errorMessage && (
                  <label style={{ color: "red", fontWeight: "bold" }}>
                    {t(errorMessage)}
                  </label>
                )}
                {!!redirectMessage && (
                  <label style={{ color: "green" }}>{redirectMessage}</label>
                )}
                <button
                  className="w-full"
                  style={{
                    height: 50,
                    marginTop: 6,
                    marginBottom: 10,
                    border:
                      paymentMethod === "UPAY"
                        ? "solid 3px #977052"
                        : "solid 1px #977052",
                    borderRadius: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    backgroundColor:
                      paymentMethod === "UPAY" ? "#d2ef9a" : "transparent",
                  }}
                  onClick={() => {
                    setPaymentMethod("UPAY");
                  }}
                >
                  {t("UPay Checkout")}
                </button>
                {hasTabby && (
                  <button
                    className="w-full"
                    style={{
                      height: 50,
                      marginTop: 6,
                      marginBottom: 10,
                      border:
                        paymentMethod === "TABBY"
                          ? "solid 3px #977052"
                          : "solid 1px #977052",
                      borderRadius: 12,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      backgroundColor:
                        paymentMethod === "TABBY" ? "#d2ef9a" : "transparent",
                    }}
                    onClick={() => {
                      setPaymentMethod("TABBY");
                    }}
                  >
                    <img
                      src={"/images/payment/tabby-logo.png"}
                      width={70}
                      height={40}
                      alt="img"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    />
                    {t("Tabby Checkout")}
                  </button>
                )}
                {hasTabby &&
                (cartTotal > 0 || discount > 0 || deliveryFees > 0) ? (
                  <TabbyCheckout
                    amount={cartTotal}
                    discount={discount}
                    delivery={deliveryFees}
                  />
                ) : (
                  <></>
                )}
                <button
                  className="button-main w-full"
                  onClick={() => handlePay()}
                >
                  {redirectMessage ? "redirecting..." : t("Confirm and Pay")}
                </button>
                <div className="infor">
                  <div className="text-on-surface-variant1 pt-4">
                    {t(
                      paymentMethod === "TABBY"
                        ? "redirectTabby"
                        : "redirectUPay"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
