import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import { FormEvent } from "react";

const ContactUs = () => {
  let handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const form = ev.target as HTMLFormElement;
    // Use FormData to get the values from the form
    const formData = new FormData(ev.target as HTMLFormElement);

    const values = {
      name: formData.get("name"),
      email: formData.get("email"),
      message: formData.get("message"),
      phoneNumber: formData.get("phoneNumber"),
      subject: formData.get("subject"),
    };
    try {
      let res = await fetch(
        "https://mail.brcodes-vps.xyz/mail/sendContact?token=HAtMgftBkdLdtMeZP8b1",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            message: values.message,
            phoneNumber: values.phoneNumber,
            to: "sloth.kw@hotmail.com",
            website: `sloth - ${values.subject}`,
          }),
        }
      );
      if (res.status === 200) {
        form.reset();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="Contact us" subHeading="Contact us" />
      </div>
      <div className="contact-us md:py-20 py-10">
        <div className="container">
          <div className="flex justify-between max-lg:flex-col gap-y-10">
            <div className="left lg:w-2/3 lg:pr-4">
              <div className="heading3">Drop Us A Line</div>
              <div className="body1 text-secondary2 mt-3">
                Use the form below to get in touch with the our team
              </div>
              <form
                className="md:mt-6 mt-4"
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                //@ts-ignore
                netlify
                onSubmit={(ev) => {
                  handleSubmit(ev);
                }}
              >
                <input type="hidden" name="form-name" value="contact" />

                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 gap-y-5">
                  <div className="name ">
                    <input
                      className="border-line px-4 py-3 w-full rounded-lg"
                      id="name"
                      type="text"
                      placeholder="Your Name *"
                      required
                      name="name"
                    />
                  </div>
                  <div className="name ">
                    <input
                      className="border-line px-4 py-3 w-full rounded-lg"
                      id="phoneNumber"
                      type="text"
                      placeholder="Your Phone Number *"
                      required
                      name="phoneNumber"
                    />
                  </div>
                  <div className="email">
                    <input
                      className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                      id="email"
                      type="email"
                      placeholder="Your Email *"
                      required
                      name="email"
                    />
                  </div>
                  <div className="name ">
                    <input
                      className="border-line px-4 py-3 w-full rounded-lg"
                      id="subject"
                      type="text"
                      placeholder="Subject *"
                      required
                      name="subject"
                    />
                  </div>
                  <div className="message sm:col-span-2">
                    <textarea
                      className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                      id="message"
                      rows={3}
                      placeholder="Your Message *"
                      required
                      name="message"
                    />
                  </div>
                </div>
                <div className="block-button md:mt-6 mt-4">
                  <button className="button-main">Send message</button>
                </div>
              </form>
            </div>
            <div className="right lg:w-1/4 lg:pl-4"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
