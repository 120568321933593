import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import OrderTracking from "./pages/OrderTracking";
import NewPassword from "./pages/NewPassword";
import ForgotPassword from "./pages/ForgotPassword";
import About from "./pages/About";
import Contact from "./pages/Contact";
import WholeSale from "./pages/Wholesale";
import FAQs from "./pages/FAQs";
import Exchange from "./pages/Exchange";
import Shop from "./pages/Shop";

import { useGetShop } from "@/api/shop";
import { useGetMenu } from "@/api/menu";
import { useGetFeatured } from "@/api/featured";

import SplashScreen from "@/components/SplashScreen/SplashScreen";

const AppRoutes = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [isLoading, setIsLoading] = useState(isHome);

  const { shopLoading } = useGetShop();
  const { menuLoading } = useGetMenu();
  const { featuredLoading } = useGetFeatured();
  useEffect(() => {
    if (isLoading) {
      return;
    }
  }, [isLoading]);

  return isHome &&
    (isLoading || shopLoading || menuLoading || featuredLoading) ? (
    <Routes>
      <Route
        path="/"
        element={
          <SplashScreen
            finishLoading={() => {
              setIsLoading(false);
            }}
          />
        }
      />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/shipping" element={<OrderTracking />} />
      <Route path="/pages/shipping" element={<OrderTracking />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/wholesale" element={<WholeSale />} />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/exchange" element={<Exchange />} />
      <Route path="/shop" element={<Shop />} />
    </Routes>
  );
};

export default AppRoutes;
