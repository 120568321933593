//@ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Rate from "@/components/Other/Rate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Scrollbar } from "swiper/modules";
import "swiper/css/bundle";
import * as Icon from "@phosphor-icons/react/dist/ssr";
import SwiperCore from "swiper";
import { useModalCartContext } from "@/context/ModalCartContext";
import { useWishlist } from "@/context/WishlistContext";
import { useModalWishlistContext } from "@/context/ModalWishlistContext";

import { readFromLocalStorage, LocalStorageKeys } from "@/hooks/localStorage";
import { RWebShare } from "react-web-share";
import ReactStars from "react-stars";
import axios, { endpoints } from "@/utils/axios";
import { useUser } from "@/context/UserContext";
import { useCart } from "@/context/CartContext";
import { ShoppingCart } from "@phosphor-icons/react";
import { useWindowSize } from "@/hooks/windowSize";
import { usePrices } from "@/context/PricesContext";
import { useLanguages } from "@/context/LanguageContext";
import TabbyComponent from "@/components/Tabby/TabbyPromo";

SwiperCore.use([Navigation, Thumbs]);

interface Props {
  prodId: string | number | null;
  catId: string | number | null;
}

const Sidebar: React.FC<Props> = ({ prodId, catId }) => {
  const { langState, t } = useLanguages();

  const { formatPrice } = usePrices();
  const [refresh, setRefresh] = useState(false);

  const { userState } = useUser();
  const user = userState.user || null;
  const { windowSize } = useWindowSize();
  const {
    productInCart,
    isAvailableInCart,
    isSimilarAvailableInCart,
    addToShoppingCart,
    increaseQuantity,
    decreaseQuantity,
  } = useCart();

  const isLoadingCart = false;
  const navigate = useNavigate();

  const [rating, setRating] = useState(5);
  const [reviewed, setReviewed] = useState(false);
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const [activeColor, setActiveColor] = useState<string>();
  const [activeSize, setActiveSize] = useState<string>();

  const [variations, setVariations] = useState<string>([]);
  const addedVariations = variations?.filter((x) => !!x.value);
  const { openModalCart } = useModalCartContext();
  const { addToWishlist, removeFromWishlist, wishlistState, isInWishList } =
    useWishlist();
  const { openModalWishlist } = useModalWishlistContext();

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const menu_ = readFromLocalStorage(LocalStorageKeys.MENU);
    const allProducts = menu_?.reduce((allProducts, category) => {
      return allProducts.concat(category.products);
    }, []);

    setMenu([
      {
        id: "all",
        name: { en: "All Products", ar: "جميع المنتجات" },
        products: allProducts,
      },
      ...(menu_ ?? []),
    ]);
  }, []);

  const [product, setProduct] = useState();
  const [category, setCategory] = useState();
  const [productCategory, setProductCategory] = useState();

  useEffect(() => {
    const cat = menu?.find((x) => x._id === catId) ?? menu?.[0];

    setCategory(cat);
    const productsList = menu?.reduce(
      (prev, curr) => [...prev, ...(curr?.products || [])],
      []
    );
    let prod = null;
    if (prodId) {
      prod = productsList?.find((x) => x._id === prodId);
      setProduct(prod);
    } else {
      setProduct();
      // prod = cat?.products?.[0] || productsList?.[0];
    }
    // setProduct(prod);
  }, [prodId, catId, menu]);

  useEffect(() => {
    if (product) {
      /*
  const colorVariationsOptions = product?.colorVariations?.options
    ?.filter(x => x.isAvailable && x.quantity > 0 && !x.isHidden)
    ?.map(x => ({
      ...x,
      sizes: x.sizes?.filter(y => y.isAvailable && x.quantity > 0),
    }));

  const sizeVariationsOptions = product?.sizeVariations?.options?.filter(
    x => x.isAvailable && x.quantity > 0 && !x.isHidden,
  );

  const hasColorSizesVariations = colorVariationsOptions?.some(
    x => x.sizes?.length > 0,
  );

  const colorOptions = colorVariationsOptions;
  const sizeOptions = hasColorSizesVariations
    ? activeColor?.sizes
        ?.filter(x => x.isAvailable && x.quantity > 0)
        ?.map(x => ({
          ...product?.sizeVariations?.options?.find(y => y.id === x.id),
          ...x,
        }))
    : sizeVariationsOptions;

      */

      const colorVariationsOptions_ = product?.colorVariations?.options
        ?.filter((x) => x.isAvailable && x.quantity > 0 && !x.isHidden)
        ?.map((x) => ({
          ...x,
          sizes: x.sizes?.filter((y) => y.isAvailable && x.quantity > 0),
        }));

      const defaultColor =
        colorVariationsOptions_?.find((x) => x.isDefault) ??
        colorVariationsOptions_[0];
      setActiveColor(defaultColor);

      const hasColorSizesVariations_ = colorVariationsOptions_?.some(
        (x) => x.sizes?.length > 0
      );

      const sizeVariationsOptions_ = hasColorSizesVariations_
        ? defaultColor?.sizes
            ?.filter((x) => x.isAvailable && x.quantity > 0)
            ?.map((x) => ({
              ...product?.sizeVariations?.options?.find((y) => y.id === x.id),
              ...x,
            }))
        : product?.sizeVariations?.options?.filter(
            (x) => x.isAvailable && x.quantity > 0 && !x.isHidden
          );
      const defaultSize =
        sizeVariationsOptions_?.find((x) => x.isDefault) ??
        sizeVariationsOptions_[0];

      setActiveSize(defaultSize);
      setVariations([
        ...(product?.variations?.map((vari) => ({
          ...vari,
          value: vari.options?.find((x) => x.isDefault)?.value || false,
          textValue: "",
        })) || []),
        ...(product?.nameVariation && product?.nameVariation?.isAvailable
          ? [
              {
                ...product?.nameVariation,
                type: product?.nameVariation?.isRequired ? "TEXT" : "TEXT_BOOL",
              },
            ]
          : []),
      ]);
      setProductCategory(
        product?.related?.length > 0
          ? {
              products: product?.related
                ?.map((x) => {
                  return menu?.[0]?.products?.find((y) => y?._id === x);
                })
                .filter((x) => !!x),
            }
          : menu?.find((x) => x._id === product?.category)
      );

      goToSlide(0);
      setRating(5);
      setReviewed(
        user?._id &&
          product?.reviews?.find((x) => user?._id && x.user?._id === user?._id)
      );
    }
  }, [product]);

  let percentSale = Math.floor(
    100 - (product?.salePrice / product?.price) * 100
  );
  const colorVariationsImages = (
    product?.colorVariations?.options
      .filter((x) => !x.isHidden)
      ?.filter((x) => x.images?.length > 0) || []
  )?.reduce((acc, curr) => {
    return [...acc, ...curr?.images];
  }, []);
  const sizeVariationsImages = (
    product?.sizeVariations?.options
      .filter((x) => !x.isHidden)
      ?.filter((x) => x.images?.length > 0) || []
  )?.reduce((acc, curr) => {
    return [...acc, ...curr?.images];
  }, []);
  const allProdImages = [
    ...new Set([
      ...(colorVariationsImages?.length > 0 || sizeVariationsImages?.length > 0
        ? []
        : [product?.imageURL]),
      ...colorVariationsImages,
      ...sizeVariationsImages,
      ...(product?.images || []),
    ]),
  ];

  const colorVariationsOptions = product?.colorVariations?.options
    ?.filter(
      (
        x // x.isAvailable &&
      ) => x.quantity > 0 && !x.isHidden
    )
    ?.map((x) => ({
      ...x,
      sizes: x.sizes?.filter(
        (
          y //y.isAvailable &&
        ) => y.quantity > 0
      ),
    }));

  const sizeVariationsOptions = product?.sizeVariations?.options?.filter(
    (x) => x.quantity > 0 && !x.isHidden
    // x => x.isAvailable && x.quantity > 0 && !x.isHidden,
  );

  const hasColorSizesVariations = colorVariationsOptions?.some(
    (x) => x.sizes?.length > 0
  );

  const colorOptions = colorVariationsOptions;
  const sizeOptions = hasColorSizesVariations
    ? activeColor?.sizes
        ?.filter((x) => x.isAvailable && x.quantity > 0)
        ?.map((x) => ({
          ...product?.sizeVariations?.options?.find((y) => y.id === x.id),
          ...x,
        }))
    : sizeVariationsOptions;

  const goToSlide = (index) => {
    if (mainSwiper) {
      mainSwiper?.slideTo(index, 1000);
    }
  };

  const handleCategoryClick = (catId_: string) => {
    navigate(`/shop?category=${catId_}`);
  };

  const handleProductClick = (prodId_: string) => {
    navigate(`/shop?product=${prodId_}`);
  };

  const handleSwiper = (swiper: SwiperCore) => {
    // Do something with the thumbsSwiper instance
    setThumbsSwiper(swiper);
  };

  const [quantityPurchase, setQuantityPurchase] = useState(0);

  let productInCart_ = productInCart(
    product?._id,
    activeColor,
    activeSize,
    addedVariations
  );
  useEffect(() => {
    setQuantityPurchase(productInCart_?.quantityPurchase || 0);
  }, [product, activeColor, activeSize, ShoppingCart]);

  const handleIncreaseQuantity = () => {
    if (maxQuantity > quantityPurchase) {
      setQuantityPurchase(quantityPurchase + 1);
    }
  };

  const handleDecreaseQuantity = () => {
    setQuantityPurchase(quantityPurchase > 0 ? quantityPurchase - 1 : 0);
  };

  const diffQuantityPurchase =
    quantityPurchase - (productInCart_?.quantityPurchase || 0);

  const maxQuantity = activeColor
    ? activeColor?.quantity
    : activeSize
    ? activeSize?.quantity
    : product?.quantity;

  const isSelectedProductAvailable =
    product?.isAvailable &&
    (activeColor ? activeColor?.isAvailable : true) &&
    (activeSize ? activeSize?.isAvailable : true);

  const availabilityDate =
    activeSize && !activeSize?.isAvailable && activeSize?.availableAt
      ? activeSize?.availableAt
      : activeColor && !activeColor?.isAvailable && activeColor?.availableAt
      ? activeColor?.availableAt
      : !product?.isAvailable && product?.availableAt
      ? product?.availableAt
      : null;

  const isSizeSet = product?.sizeVariations?.isRequired ? !!activeSize : true;

  const isColorSet = product?.colorVariations?.isRequired
    ? !!activeColor
    : true;

  const isNameSet = product?.nameVariation?.isRequired
    ? variations?.filter((x) => !!x.value || !!x.textValue)
    : true;

  const handleAddToCart = () => {
    const isPreOrder = !isSelectedProductAvailable;

    if (!isInCart) {
      addToShoppingCart({
        productId: product?._id,
        color: activeColor || null,
        size: activeSize || null,
        variations: addedVariations,
        quantityPurchase: diffQuantityPurchase === 0 ? 1 : diffQuantityPurchase,
        isPreOrder,
        availabilityDate,
      });
    } else if (diffQuantityPurchase === 0) {
      addToShoppingCart({
        productId: product?._id,
        color: activeColor || null,
        size: activeSize || null,
        variations: addedVariations,
        quantityPurchase: 1,
        isPreOrder,
        availabilityDate,
      });
    } else if (diffQuantityPurchase > 0) {
      increaseQuantity({
        productId: product?._id,
        color: activeColor || null,
        size: activeSize || null,
        variations: addedVariations,
        quantityPurchase: diffQuantityPurchase,
        isPreOrder,
        availabilityDate,
      });
    } else if (diffQuantityPurchase < 0) {
      decreaseQuantity({
        productId: product?._id,
        color: activeColor || null,
        size: activeSize || null,
        variations: addedVariations,
        quantityPurchase: diffQuantityPurchase,
      });
    }
    openModalCart();
  };

  const handleAddToWishlist = () => {
    if (!isInWishList(product?._id)) {
      addToWishlist(product);
    } else {
      removeFromWishlist(product?._id);
    }
    openModalWishlist();
  };

  const handleSubmitReview = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.target);
      const values = {
        rating, // Use the state value
        productId: product?._id,
        comment: formData.get("comment"),
      };

      const resp = await axios.post(endpoints.review.add, values);

      if (resp.status === 200) {
      }
    } catch (error) {
      // setErrorMessage("Something went wrong! Please try again.");
    } finally {
      setReviewed(true);
    }
  };

  const isInCart = isAvailableInCart(
    product?._id,
    activeColor,
    activeSize,
    addedVariations
  );

  const totalPrice =
    product?.price +
    (activeColor?.additionalPrice || 0) +
    (activeSize?.additionalPrice || 0) +
    (variations || [])?.reduce(
      (acc, curr) => (acc + curr?.value ? curr.additionalPrice : 0),
      0
    );
  const totalSalePrice =
    product?.salePrice +
    (activeColor?.additionalPrice || 0) +
    (activeSize?.additionalPrice || 0) +
    (variations || [])?.reduce(
      (acc, curr) => (acc + curr?.value ? curr.additionalPrice : 0),
      0
    );

  const renderMainImage = useCallback(
    (children) => {
      return (
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[Thumbs]}
          className="mySwiper2 rounded-2xl overflow-hidden"
          onSwiper={setMainSwiper}
        >
          {children}
        </Swiper>
      );
    },
    [setMainSwiper]
  );
  return (
    <>
      <div className="product-detail sale sidebar">
        <div className="featured-product underwear md:py-20 py-10">
          <div className="container flex justify-between gap-y-6 flex-wrap ">
            <div className="sidebar lg:w-1/4 max-sm:w-full sm:w-[60%] lg:hidden md:hidden sm:pr-10 w-full">
              <div className="filter-type pb-8 border-b border-line">
                {product && (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      setProduct();
                    }}
                  >
                    <Icon.ArrowLeft />
                    {t("Back")}
                  </div>
                )}
                <div className="heading5">{t("Categories")}</div>
                <div className="select-block relative">
                  <select
                    id="category"
                    name="category"
                    className="text-button py-2 pl-3 md:pr-14 pr-10 rounded-lg bg-white border border-line"
                    onChange={(ev) => {
                      handleCategoryClick(ev.target.value);
                    }}
                  >
                    {menu?.map((item, index) => (
                      <option value={item?._id}>
                        {item?.name?.[langState?.lang]}
                      </option>
                    ))}
                  </select>
                  <Icon.CaretDown
                    size={12}
                    className="absolute top-1/2 -translate-y-1/2 md:right-4 right-2"
                  />
                </div>
              </div>
            </div>
            {/* <div
              className={`list-collection section-swiper-navigation px-4`}
              style={{ maxWidth: windowSize?.width - 32 || 500 }}
            >
              {category?.products?.length > 0 ? (
                <Swiper
                  spaceBetween={4}
                  slidesPerView={2}
                  navigation
                  loop={true}
                  modules={[Navigation]}
                  breakpoints={{
                    576: {
                      slidesPerView: Math.min(category?.products?.length, 4),
                      spaceBetween: 12,
                    },
                    768: {
                      slidesPerView: Math.min(category?.products?.length, 6),
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: Math.min(category?.products?.length, 8),
                      spaceBetween: 20,
                    },
                  }}
                  className='h-full'
                >
                  {category?.products?.map(
                    (productCat: {
                      imageURL: string;
                      name: { en: string };
                    }) => (
                      <SwiperSlide>
                        <div
                          onClick={() => {
                            setProduct(productCat);
                          }}
                        >
                          <div className='collection-item block relative rounded-xl overflow-hidden cursor-pointer'>
                            <div className='bg-img'>
                              <Image
                                src={productCat.imageURL}
                                width={200}
                                height={200}
                                alt={productCat.name?.[langState?.lang]}
                                className='rounded-xl'
                              />
                            </div>
                          </div>
                          <div
                            style={{ maxWidth: 200 }}
                            className={`collection-name subtitle text-center sm:bottom-8 bottom-4 sm:w-full md:py-3 py-1.5 bg-white rounded-xl duration-500 ${productCat._id === product?._id ? "font-bold" : ""}`}
                          >
                            {`${productCat.name?.[langState?.lang]?.split(" ")[0]} ${productCat.name?.[langState?.lang]?.split(" ")[1] ?? ""} ${productCat.name?.[langState?.lang]?.split(" ")[2] ?? ""} ${productCat.name?.[langState?.lang]?.split(" ")[3] ? productCat.name?.[langState?.lang]?.split(" ")[3] + "..." : ""}`}
                          </div>
                        </div>
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              ) : (
                <></>
              )}
               </div> */}

            <div className="sidebar lg:w-1/4 md:w-1/4 max-sm:hidden sm:hidden md:block lg:block lg:pr-12 ">
              <div className="filter-type pb-8 border-b border-line">
                {product && (
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      setProduct();
                    }}
                  >
                    <Icon.ArrowLeft />
                    {t("Back")}
                  </div>
                )}
                <div className="heading5">{t("Categories")}</div>
                <div className="list-type mt-4">
                  {menu?.map((item, index) => (
                    <div
                      key={index}
                      className={`item flex items-center justify-between cursor-pointer`}
                      onClick={() => handleCategoryClick(item?._id)}
                    >
                      <div
                        className={`text-secondary has-line-before hover:text-black capitalize ${
                          category?._id === item?._id ? "font-bold" : ""
                        }`}
                      >
                        {item?.name?.[langState?.lang]}
                      </div>
                      <div className="text-secondary2">
                        ({item?.products?.length})
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {productCategory && (
                <div className="mt-8">
                  <div className="heading6">{t("You'll love this too")}</div>
                  <div className="list">
                    {[
                      ...(productCategory?.products || []),
                      ...(productCategory?.products || []),
                    ]
                      ?.slice(0, 3)
                      .map((item) => (
                        <div
                          key={item?.id}
                          className="item flex items-center gap-5 mt-4 cursor-pointer"
                          onClick={() => handleProductClick(item?._id)}
                        >
                          <img
                            src={item?.imageURL}
                            width={300}
                            height={500}
                            alt={item?.imageURL}
                            className="w-20 aspect-[3/4] object-cover rounded-lg flex-shrink-0"
                          />
                          <div>
                            <div className="text-title">
                              {item?.name?.[langState?.lang]}
                            </div>
                            <div className="text-title mt-1">
                              {formatPrice(item?.price, item?.currency)}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <div className="right lg:w-3/4 sm:w-[60%] sm:pl-3 w-full">
              {!product && (
                <div
                  className="list-collection section-swiper-navigation sm:px-5 px-4"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {category?.products?.map((product_) => (
                    <div
                      className="collection-item block relative rounded-2xl overflow-hidden cursor-pointer max-sm:w-[45%] sm:w-[46%] md:w-[30%] lg:w-[22%]"
                      style={{ margin: 4, minHeight: 240 }}
                    >
                      <div
                        className="collection-item block relative rounded-2xl overflow-hidden cursor-pointer"
                        onClick={() => {
                          setProduct(product_);
                        }}
                      >
                        <div className="bg-img">
                          <img
                            src={product_.imageURL}
                            width={200}
                            height={200}
                            alt={product_.name?.[langState?.lang]}
                          />
                        </div>
                      </div>
                      <div
                        style={{ width: "100%" }}
                        className="collection-name subtitle2 text-center sm:bottom-0 bottom-0 lg:w-[200px] md:w-[160px] w-[100px] md:py-1 py-0.5 bg-white rounded-xl duration-500"
                        onClick={() => {
                          setProduct(product_);
                        }}
                      >
                        {product_.name?.[langState?.lang]}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!!product && (
                <div className="flex max-lg:flex-col justify-between gap-y-6">
                  <div className="list-img lg:w-1/2 lg:pr-[40px] w-full">
                    {renderMainImage(
                      allProdImages?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={item}
                            width={1000}
                            height={1000}
                            alt="prd-img"
                            className="w-full aspect-[1/1] object-cover"
                          />
                        </SwiperSlide>
                      ))
                    )}
                    <Swiper
                      onSwiper={handleSwiper}
                      spaceBetween={0}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[Navigation, Thumbs]}
                      className="mySwiper style-rectangle"
                    >
                      {allProdImages?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={item}
                            width={100}
                            height={100}
                            alt="prd-img"
                            className="w-full aspect-[1/1] object-cover rounded-xl"
                            onClick={() => {
                              let imgIndex = allProdImages.findIndex(
                                (x) => x === item
                              );
                              if (imgIndex >= 0) {
                                goToSlide(imgIndex);
                              }
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="product-infor lg:w-1/2 w-full">
                    <div className="flex justify-between">
                      <div>
                        <div className="heading4 mt-1">
                          {product?.name?.[langState?.lang]}
                        </div>
                      </div>
                      <div
                        className={`add-wishlist-btn w-12 h-12 flex items-center justify-center border border-line cursor-pointer rounded-xl duration-300 hover:bg-black hover:text-white ${
                          wishlistState.wishlistArray.some(
                            (item) => item?.id === product?.id
                          )
                            ? "active"
                            : ""
                        }`}
                        onClick={handleAddToWishlist}
                      >
                        {wishlistState.wishlistArray.some(
                          (item) => item?.id === product?.id
                        ) ? (
                          <>
                            <Icon.Heart
                              size={24}
                              weight="fill"
                              className="text-white"
                            />
                          </>
                        ) : (
                          <>
                            <Icon.Heart size={24} />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center mt-3">
                      <Rate currentRate={product?.rate} size={14} />
                      <span className="caption1 text-secondary">
                        ({product?.totalNmbRatings} {t("Reviews")})
                      </span>
                    </div>
                    <div className="flex items-center gap-3 flex-wrap mt-5 pb-6 border-b border-line">
                      {product?.salePrice ? (
                        <>
                          <div className="product-price heading5">
                            {formatPrice(totalSalePrice, product?.currency)}
                          </div>
                          <div className="w-px h-4 bg-line"></div>
                          <div className="product-origin-price font-normal text-secondary2">
                            <del>
                              {formatPrice(totalPrice, product?.currency)}
                            </del>
                          </div>
                          {product?.originPrice && (
                            <div className="product-sale caption2 font-semibold bg-green px-3 py-0.5 inline-block rounded-full">
                              -{percentSale}%
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="product-price heading5">
                          {formatPrice(totalPrice, product?.currency)}
                        </div>
                      )}

                      <div className="desc text-secondary mt-3">
                        {product?.description?.[langState?.lang]}
                      </div>
                    </div>
                    <div className="list-action mt-6">
                      {colorOptions?.length ? (
                        <div className="choose-color mt-5">
                          <div className="text-title">
                            {t("Colors")}:{" "}
                            <span className="text-title color">
                              {activeColor?.name?.[langState?.lang]}
                            </span>
                          </div>
                          <div className="list-color flex items-center gap-2 flex-wrap mt-3">
                            {colorOptions?.map((item, index) => (
                              <div
                                style={{
                                  border:
                                    activeColor?.id === item?.id
                                      ? "solid 3px #977052"
                                      : "solid 1px #ddcdbd",
                                  width: 60,
                                  height: 60,
                                  borderRadius: 12,
                                }}
                                className={`color-item duration-300 relative ${
                                  activeColor?.id === item?.id ? "active" : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setActiveColor({
                                    ...item,
                                    additionalPrice:
                                      product?.colorVariations
                                        ?.additionalPrice ||
                                      item?.additionalPrice ||
                                      0,
                                  });
                                  let imgIndex = allProdImages.findIndex(
                                    (x) => x === item?.imageURL
                                  );
                                  if (imgIndex >= 0) {
                                    goToSlide(imgIndex);
                                  }
                                }}
                              >
                                {item?.imageURL ? (
                                  <img
                                    src={item?.imageURL}
                                    width={60}
                                    height={60}
                                    alt={item?.name?.[langState?.lang]}
                                    style={{
                                      borderRadius: 12,
                                      backgroundColor: item?.colorCode,
                                    }}
                                  />
                                ) : item?.colorCode ? (
                                  <div
                                    style={{
                                      backgroundColor: item?.colorCode,
                                      width:
                                        activeColor?.id === item?.id ? 54 : 58,
                                      height:
                                        activeColor?.id === item?.id ? 54 : 58,
                                      borderRadius: 10,
                                    }}
                                  />
                                ) : (
                                  <div>{item?.name?.[langState?.lang]}</div>
                                )}

                                <div className="tag-action bg-black text-white caption2 capitalize px-1.5 py-0.5 rounded-sm">
                                  {item?.name?.[langState?.lang]}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {sizeOptions?.length ? (
                        <div className="choose-size mt-5">
                          <div className="heading flex items-center justify-between">
                            <div className="text-title">
                              {t("Size")}:{" "}
                              <span className="text-title size">
                                {activeSize?.name?.[langState?.lang]}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {sizeOptions?.map((item, index) => (
                              <div
                                className="list-size flex items-center gap-2 flex-wrap mt-3"
                                style={{ marginLeft: 10 }}
                              >
                                <div
                                  className={`size-item ${
                                    item === "freesize"
                                      ? "px-3 py-2"
                                      : "w-14 h-14"
                                  } flex items-center justify-center text-button rounded-full bg-white border border-line ${
                                    activeSize?.id === item?.id ? "active" : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    let imgIndex = allProdImages.findIndex(
                                      (x) => x === item?.imageURL
                                    );
                                    if (imgIndex >= 0) {
                                      goToSlide(imgIndex);
                                    }

                                    setActiveSize({
                                      ...item,
                                      additionalPrice:
                                        product?.sizeVariations
                                          ?.additionalPrice ||
                                        item?.additionalPrice ||
                                        0,
                                    });
                                  }}
                                >
                                  {item?.name?.[langState?.lang]}
                                </div>
                              </div>
                            ))}
                          </div>

                          {}
                        </div>
                      ) : (
                        <></>
                      )}
                      {variations?.map((variation) => (
                        <>
                          {variation?.type === "CHECKBOX" ? (
                            <div className="choose-size mt-5">
                              <div className="heading flex items-center justify-between">
                                <div className="text-title">
                                  {variation?.name?.[langState?.lang]}:
                                </div>
                                <div className="left flex items-center cursor-pointer">
                                  <div className="block-input">
                                    <input
                                      type="checkbox"
                                      name={variation?.name?.[langState?.lang]}
                                      id={variation?.id}
                                      checked={true}
                                      onChange={() => {}}
                                    />
                                    <Icon.CheckSquare
                                      size={20}
                                      weight="fill"
                                      className="icon-checkbox"
                                    />
                                  </div>
                                  <label
                                    htmlFor={variation?.id}
                                    className="brand-name capitalize pl-2 cursor-pointer"
                                  >
                                    {`${
                                      variation?.name?.[langState?.lang]
                                    } (+${formatPrice(
                                      variation?.additionalPrice,
                                      product?.currency
                                    )})`}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {variation?.type === "TEXT" ? (
                            <div className="choose-size mt-5">
                              <div className="heading flex items-center justify-between">
                                <div className="text-title">
                                  {variation?.name?.[langState?.lang]}:
                                </div>
                                <div className="left flex items-center cursor-pointer">
                                  <input
                                    className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                                    id={variation?.id}
                                    type="text"
                                    placeholder={`${
                                      variation?.name?.[langState?.lang]
                                    }*`}
                                    minLength={variation?.min}
                                    maxLength={variation?.max}
                                    // required
                                    onChange={(ev) => {
                                      let tempVariations = variations;
                                      tempVariations.find(
                                        (x) => x.id === variation?.id
                                      ).value = true;
                                      tempVariations.find(
                                        (x) => x.id === variation?.id
                                      ).textValue = ev.target.value;
                                      setVariations(tempVariations);
                                      setRefresh(!refresh);
                                    }}
                                  />

                                  <label
                                    htmlFor={variation?.id}
                                    className="brand-name capitalize pl-2 cursor-pointer"
                                  >
                                    {`${formatPrice(
                                      variation?.additionalPrice,
                                      product?.currency
                                    )}`}
                                  </label>
                                </div>
                              </div>
                              <label
                                htmlFor={variation?.id}
                                className="brand-name capitalize pl-2 cursor-pointer"
                              >
                                {variation?.max > 0
                                  ? `max: ${variation?.max} characters`
                                  : ""}
                              </label>
                            </div>
                          ) : (
                            <></>
                          )}
                          {variation?.type === "TEXT_BOOL" ? (
                            <>
                              <div className="choose-size mt-5">
                                <div className="left flex items-center cursor-pointer">
                                  <div className="left flex items-center cursor-pointer">
                                    <div className="block-input">
                                      <input
                                        type="checkbox"
                                        name={
                                          variation?.name?.[langState?.lang]
                                        }
                                        id={variation?.id}
                                        checked={variation?.enabled}
                                        onChange={(ev) => {
                                          let tempVariations = variations;
                                          tempVariations.find(
                                            (x) => x.id === variation?.id
                                          ).value = ev.target.checked;
                                          setVariations(tempVariations);
                                          setRefresh(!refresh);
                                        }}
                                      />
                                      <Icon.CheckSquare
                                        size={20}
                                        weight="fill"
                                        className="icon-checkbox"
                                      />
                                    </div>
                                    <label
                                      htmlFor={variation?.id}
                                      className="brand-name capitalize pl-2 cursor-pointer"
                                    >
                                      {`${
                                        variation?.name?.[langState?.lang]
                                      } (+${formatPrice(
                                        variation?.additionalPrice,
                                        product?.currency
                                      )})`}
                                    </label>
                                  </div>
                                  <input
                                    disabled={!variation?.value}
                                    className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                                    id={variation?.id}
                                    type="text"
                                    placeholder={
                                      variation?.name?.[langState?.lang]
                                    }
                                    minLength={variation?.min}
                                    maxLength={variation?.max}
                                    value={variation?.textValue}
                                    onChange={(ev) => {
                                      let tempVariations = variations;
                                      tempVariations.find(
                                        (x) => x.id === variation?.id
                                      ).textValue = ev.target.value;
                                      setVariations(tempVariations);
                                      setRefresh(!refresh);
                                    }}
                                  />
                                </div>
                                <label
                                  htmlFor={variation?.id}
                                  className="brand-name capitalize pl-2 cursor-pointer"
                                >
                                  {variation?.max > 0
                                    ? `max: ${variation?.max} characters`
                                    : ""}
                                </label>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                      <div className="text-title mt-5">{t("Quantity")}:</div>
                      <div className="choose-quantity flex items-center lg:justify-between gap-5 gap-y-3 mt-3">
                        <div className="quantity-block md:p-3 max-md:py-1.5 max-md:px-3 flex items-center justify-between rounded-lg border border-line sm:w-[180px] w-[120px] flex-shrink-0">
                          <Icon.Minus
                            size={20}
                            onClick={handleDecreaseQuantity}
                            className={`${
                              quantityPurchase === 1 ? "disabled" : ""
                            } cursor-pointer`}
                          />
                          <div className="body1 font-semibold">
                            {quantityPurchase || 0}
                          </div>
                          <Icon.Plus
                            size={20}
                            onClick={handleIncreaseQuantity}
                            className="cursor-pointer"
                          />
                        </div>
                        <label className="brand-name capitalize pl-2 cursor-pointer">
                          {quantityPurchase === maxQuantity
                            ? langState?.lang === "ar"
                              ? `متوفر ${maxQuantity} فقط`
                              : `only ${maxQuantity} are available`
                            : ""}
                        </label>
                      </div>
                      {isInCart && (
                        <div className="compare flex items-center gap-1 ">
                          <div className="text-secondary">
                            {t("Product is already in your cart")}
                          </div>
                        </div>
                      )}
                      {isSimilarAvailableInCart(
                        product?._id,
                        activeColor,
                        activeSize,
                        addedVariations
                      ) && (
                        <div className="compare flex items-center gap-1 ">
                          <div className="text-secondary">
                            {t("Similar Product is alreday in your cart")}
                          </div>
                        </div>
                      )}
                      {!maxQuantity || !isSelectedProductAvailable ? (
                        <div className="button-block mt-5">
                          <div className="w-full text-center">
                            {t("Item is Not Available Currently")}
                          </div>
                        </div>
                      ) : !isNameSet ? (
                        <div className="button-block mt-5">
                          <div className="w-full text-center">
                            {t("Please Add the Baby Name")}
                          </div>
                        </div>
                      ) : !isColorSet ? (
                        <div className="button-block mt-5">
                          <div className="w-full text-center">
                            {t("Please Select a Color")}
                          </div>
                        </div>
                      ) : !isSizeSet ? (
                        <div className="button-block mt-5">
                          <div className="w-full text-center">
                            {t("Please Select a Size")}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="button-block mt-5"
                          onClick={handleAddToCart}
                        >
                          <div className="button-main w-full text-center">
                            {isLoadingCart
                              ? "..."
                              : diffQuantityPurchase === 0
                              ? t("Add To Cart")
                              : diffQuantityPurchase > 0
                              ? `${t("Add")} ${diffQuantityPurchase} ${t(
                                  "To Cart"
                                )}`
                              : `${t("Remove")} ${
                                  -1 * diffQuantityPurchase
                                } ${t("From Cart")}`}
                          </div>
                        </div>
                      )}

                      {maxQuantity && !isSelectedProductAvailable ? (
                        !!availabilityDate ? (
                          <div className="button-block mt-5">
                            <div className="w-full text-center ">
                              {`${t("It will be available around")}:`}
                            </div>
                            <div
                              className="w-full text-center "
                              style={{ fontWeight: "bold", fontSize: 20 }}
                            >
                              {`${availabilityDate?.split("T")?.[0]}`}
                            </div>
                            <div className="w-full text-center ">
                              {`${t("You can still pre-order it now")}`}
                            </div>
                            <div
                              className="button-block mt-5"
                              onClick={handleAddToCart}
                            >
                              <div className="button-main w-full text-center">
                                {isLoadingCart
                                  ? "..."
                                  : diffQuantityPurchase === 0
                                  ? t("Add To Cart")
                                  : diffQuantityPurchase > 0
                                  ? `${t("Add")} ${diffQuantityPurchase} ${t(
                                      "To Cart"
                                    )}`
                                  : `${t("Remove")} ${
                                      -1 * diffQuantityPurchase
                                    } ${t("From Cart")}`}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <TabbyComponent amount={totalPrice} />

                      <div className="flex items-center lg:gap-20 gap-8 mt-5">
                        <div className="compare flex items-center gap-3 cursor-pointer">
                          <div className="text-title">Category:</div>
                          <div className="text-secondary">
                            {
                              menu?.find((x) => x._id === product?.category)
                                ?.name?.[langState?.lang]
                            }
                          </div>
                        </div>

                        <RWebShare
                          data={{
                            text: "Sloth Online Shop",
                            url: [`/shop?product=${product?._id}`],
                            title: product?.name?.[langState?.lang],
                          }}
                          onClick={() => console.log("shared successfully!")}
                        >
                          <div className="share flex items-center gap-3 cursor-pointer">
                            <div className="share-btn md:w-12 md:h-12 w-10 h-10 flex items-center justify-center border border-line cursor-pointer rounded-xl duration-300 hover:bg-black hover:text-white">
                              <Icon.ShareNetwork
                                weight="fill"
                                className="heading6"
                              />
                            </div>
                            <span>{t("Share Products")}</span>
                          </div>
                        </RWebShare>
                      </div>
                      <div className="more-infor mt-6">
                        {product?.sku && (
                          <div className="flex items-center gap-1 mt-3">
                            <div className="text-title">SKU:</div>
                            <div className="text-secondary">{product?.sku}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="max-sm:block md:hidden">
                {productCategory && (
                  <div className="mt-8">
                    <div className="heading6">{t("You'll love this too")}</div>
                    <div className="list">
                      {[
                        ...(productCategory?.products || []),
                        ...(productCategory?.products || []),
                      ]
                        ?.slice(0, 3)
                        .map((item) => (
                          <div
                            key={item?.id}
                            className="item flex items-center gap-5 mt-4 cursor-pointer"
                            onClick={() => handleProductClick(item?._id)}
                          >
                            <img
                              src={item?.imageURL}
                              width={300}
                              height={500}
                              alt={item?.imageURL}
                              className="w-20 aspect-[3/4] object-cover rounded-lg flex-shrink-0"
                            />
                            <div>
                              <div className="text-title">
                                {item?.name?.[langState?.lang]}
                              </div>
                              <div className="text-title mt-1">
                                {formatPrice(item?.price, item?.currency)}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              {product ? (
                <div className="desc-tab mt-[60px]">
                  <div className="flex items-center w-full">
                    <div className="menu-tab flex items-center lg:gap-[60px] gap-8">
                      <div
                        className={`tab-item heading5 has-line-before text-secondary2 hover:text-black duration-300 active`}
                      >
                        {t("Reviews")}
                      </div>
                    </div>
                  </div>
                  <div className="desc-block mt-8">
                    <div className={`desc-item review-block open`}>
                      <div className="top-overview flex max-lg:flex-col lg:items-center max-sm:items-center justify-between gap-12 gap-y-4">
                        <div className="left flex max-sm:flex-col gap-y-4 items-center justify-between lg:w-1/2 w-full lg:pr-5">
                          <div className="rating black-start flex flex-col items-center">
                            <div className="text-display">
                              {product?.averageRating}
                            </div>
                            <Rate
                              currentRate={product?.averageRating}
                              size={18}
                            />
                            <div className="text-center whitespace-nowrap mt-1">
                              ({product?.totalNmbRatings} Ratings)
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <Link
                            to={"#form-review"}
                            className="button-main bg-white text-black border border-black whitespace-nowrap md:hidden"
                          >
                            {t("Write Reviews")}
                          </Link>
                        </div>
                      </div>
                      <div className="mt-8">
                        <div className="heading flex items-center justify-between flex-wrap gap-4">
                          <div className="heading4">
                            {`${
                              product?.reviews?.filter((x) => x.isApproved)
                                ?.length
                            }`}{" "}
                            {t("Comments")}
                          </div>
                          <div className="right flex items-center gap-3"></div>
                        </div>
                        {product?.reviews
                          ?.filter((x) => x.isApproved)
                          ?.map((review) => (
                            <div className="list-review mt-6">
                              <div className="item mt-8">
                                <div className="heading flex items-center justify-between">
                                  <div className="user-infor flex gap-4">
                                    <div className="avatar">
                                      <img
                                        src={
                                          review.user?.profileImageURL ||
                                          "/images/avatar/700x700.png"
                                        }
                                        width={200}
                                        height={200}
                                        alt="img"
                                        className="w-[52px] aspect-square rounded-full"
                                      />
                                    </div>
                                    <div className="user">
                                      <div className="flex items-center gap-2">
                                        <div className="text-title">
                                          {review?.user?.name}
                                        </div>
                                        <div className="span text-line">-</div>
                                        <Rate
                                          currentRate={review?.rating}
                                          size={12}
                                        />
                                      </div>
                                      <div className="flex items-center gap-2">
                                        <div className="text-secondary2">
                                          {review?.createdAt?.split("T")?.[0]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="more-action cursor-pointer">
                                    <Icon.DotsThree size={24} weight="bold" />
                                  </div>
                                </div>
                                <div className="mt-3">{review.comment}</div>
                              </div>
                            </div>
                          ))}

                        {!user ? (
                          <div id="form-review" className="form-review pt-6">
                            <div className="heading4">
                              {t("Login to review this product")}
                            </div>
                            <div className="block-button md:mt-7 mt-4">
                              <Link to={"/login"} className="button-main">
                                {t("Login")}
                              </Link>
                            </div>
                          </div>
                        ) : reviewed ? (
                          <div id="form-review" className="form-review pt-6">
                            <div className="heading4">
                              {t("You already reviewed this product")}
                            </div>
                          </div>
                        ) : (
                          <div id="form-review" className="form-review pt-6">
                            <div className="heading4">
                              {t("Leave A Review")}
                            </div>
                            <form
                              className="grid sm:grid-cols-2 gap-4 gap-y-5 md:mt-6 mt-3"
                              onSubmit={handleSubmitReview}
                            >
                              <div className="rate ">
                                <ReactStars
                                  id="rating"
                                  name="rating"
                                  count={5}
                                  size={24}
                                  color2={"#ECB018"}
                                  value={rating}
                                  onChange={(newRating) => setRating(newRating)}
                                />
                              </div>
                              <div className="col-span-full message">
                                <textarea
                                  className="border border-line px-4 py-3 w-full rounded-lg"
                                  id="comment"
                                  name="comment"
                                  placeholder="Your comment *"
                                  // required
                                ></textarea>
                              </div>
                              <div className="col-span-full sm:pt-3">
                                <button
                                  disabled={!rating}
                                  className="button-main bg-white text-black border border-black"
                                >
                                  {t("Submit Reviews")}
                                </button>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
