import { url } from "inspector";
import React from "react";
import Marquee from "react-fast-marquee";

interface Props {
  props: string;
  textColor: string;
  bgLine: string;
  message: string;
}

const BannerTop: React.FC<Props> = ({ props, message, textColor, bgLine }) => {
  return (
    <>
      <div
        className={`banner-top ${props}`}
        // style={{
        //   backgroundImage: `url('./bg-img-3.jpeg')`,
        //   height: "500px",
        //   width: "100%",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
        // style="background-image: url('/path/to/your/image/bg-img-2.jpeg'); height: 500px; width: 100%; background-size: cover;"
      >
        <Marquee>
          <div className={`line w-16 h-px ${bgLine}`}></div>
          <div className={`line w-16 h-px ${bgLine}`}></div>
          <div className={`text-button-uppercase px-8 ${textColor}`}>
            {message}
          </div>
          <div className={`line w-16 h-px ${bgLine}`}></div>
          <div className={`line w-16 h-px ${bgLine}`}></div>
        </Marquee>
      </div>
    </>
  );
};

export default BannerTop;
