//@ts-nocheck
// LanguagesContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useReducer,
  useEffect,
} from "react";
import useLocalStorage, { LocalStorageKeys } from "@/hooks/localStorage";
// import useTranslation from "@/hooks/useTranslation";
// import { useTranslation } from "next-i18next";

const translations = {
  ar: {
    "View All Products": "عرض كافة المنتجات",
    Home: "الرئيسية",
    Shop: "المتجر",
    Product: "المنتج",
    Support: "الدعم",
    "Best Sellers": "الأكثر مبيعًا",
    "On Sale": "التخفيضات",
    "New Arrivals": "الوافدون الجدد",
    "About Us": "معلومات عنا",
    "Contact Us": "اتصل بنا",
    "Shipping & Delivery": "الشحن والتوصيل",
    "Exchange & Return": "استبدال وإرجاع",
    FAQs: "الأسئلة الشائعة",
    "Wholesale/Become a distributor": "الجملة / أصبح موزعًا",
    Login: "تسجيل الدخول",
    "Don’t have an account?": "لا تملك حسابًا؟",
    Register: "تسجيل",
    Logout: "تسجيل الخروج",
    Welcome: "أهلا بك",
    Back: "العودة",
    Sales: "المبيعات",
    "View All": "عرض الكل",
    Recommended: "موصى به",
    Newletter: "النشرة الإخبارية",
    "Sign up for our newsletter": "اشترك في نشرتنا الإخبارية",
    Shipping: "الشحن",
    Wholesale: "البيع بالجملة",
    "Mail:": "البريد:",
    "Phone:": "الهاتف:",
    "best sellers": "أفضل المبيعات",
    "on sale": "العروض",
    "new arrivals": "جديدنا",
    Collections: "المجموعات",
    Categories: "الفئات",
    Colors: "الألوان",
    Size: "الحجم",
    Quantity: "الكمية",
    "Product is already in your cart": "المنتج موجود بالفعل في سلتك",
    "Similar Product is alreday in your cart":
      "منتج مشابه موجود بالفعل في سلتك",
    "Login To Add To Cart": "تسجيل الدخول لإضافة إلى السلة",
    Category: "الفئة",
    "Share Products": "مشاركة المنتجات",
    Reviews: "المراجعات",
    Comments: "التعليقات",
    "Write Reviews": "كتابة مراجعة",
    "Login to review this product": "تسجيل الدخول لمراجعة هذا المنتج",
    "You already reviewed this product": "لقد قمت بالفعل بمراجعة هذا المنتج",
    "Leave A Review": "اترك مراجعة",
    "Your comment *": "تعليقك *",
    "Submit Reviews": "إرسال المراجعات",
    "You'll love this too": "سيعجبك هذا أيضًا",
    "Add To Cart": "أضف إلى السلة",
    Add: "إضافة",
    Remove: "إزالة",
    "To Cart": "إلى السلة",
    "From Cart": "من السلة",
    "Please fill the order tracking number!": "الرجاء ملء رقم تتبع الطلب!",
    "An error occured! Please try again.": "حدث خطأ! الرجاء المحاولة مرة أخرى.",
    "Order Tracking": "تتبع الطلب",
    "To track your order please enter your Order ID in the box below and press the Track button. This was given to you on your receipt and in the confirmation email you should have received.":
      "لتتبع طلبك، يرجى إدخال رقم طلبك في الصندوق أدناه واضغط على زر التتبع. تم إعطاؤك هذا في إيصالك وفي البريد الإلكتروني للتأكيد الذي يجب أن تكون قد تلقيته.",
    "Tracking Number *": "رقم التتبع *",
    "Tracking Order": "تتبع الطلب",
    "Order Created": "تم إنشاء الطلب",
    "Order Paid": "تم دفع الطلب",
    "Order Shipped": "تم شحن الطلب",
    "Order Delivered": "تم توصيل الطلب",
    "Order Canceled": "تم إلغاء الطلب",
    Information: "المعلومات",
    "Delivery Address": "عنوان التوصيل",
    "Your Order": "طلبك",
    "No product in cart": "لا يوجد منتج في السلة",
    Subtotal: "المجموع الفرعي",

    "Please add your address": "الرجاء إضافة عنوانك",
    Discounts: "الخصومات",
    Total: "المجموع",
    "Confirm and Pay": "تأكيد والدفع",
    "UPay Checkout": "ادفع المبلغ كاملا مرة واحدة.",
    "Tabby Checkout": "ادفع على 4 دفعات. لا فوائد، لا رسوم.",
    "Use any Card": "استخدم أي بطاقة",
    "Tabby Error Not Available":
      "نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.",
    "Tabby Error Amount Too High":
      "قيمة الطلب تفوق الحد الأقصى المسموح به حاليًا مع تابي. يُرجى تخفيض قيمة السلة أو استخدام وسيلة دفع أخرى.",
    "Tabby Error Amount Too Low":
      "قيمة الطلب أقل من الحد الأدنى المطلوب لاستخدام خدمة تابي. يُرجى زيادة قيمة الطلب أو استخدام وسيلة دفع أخرى.",
    "Tabby Cancelation":
      "لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخرى.",
    "Tabby Failure":
      "نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.",
    "You will be redirect to U-Pay secure payment Gateway.":
      "سيتم توجيهك إلى بوابة دفع U-Pay الآمنة.",
    redirectUPay: "سيتم توجيهك إلى بوابة دفع U-Pay الآمنة.",
    redirectTabby: "سيتم توجيهك إلى بوابة دفع Tabby الآمنة.",
    "You will be redirect to U-Pay secure payment Gateway.":
      "سيتم توجيهك إلى بوابة دفع U-Pay الآمنة.",
    "Shopping Cart": "سلة التسوق",
    Note: "ملاحظة",
    Coupon: "كوبون",
    "View cart": "عرض السلة",
    "Check Out": "الدفع",
    "Or continue shopping": "أو استمر في التسوق",
    "Estimate shipping rates": "تقدير تكاليف الشحن",
    Country: "الدولة",
    "Shipping Fees:": "رسوم الشحن:",
    Close: "إغلاق",
    "Add A Coupon Code": "إضافة كود كوبون",
    "Enter Code": "أدخل الكود",
    "Discount code": "كود الخصم",
    Apply: "تطبيق",
    Cancel: "إلغاء",
    "Add special instructions for your order...": "أضف تعليمات خاصة لطلبك...",
    Save: "حفظ",
    "Shopping cart": "عربة التسوق",
    Products: "المنتجات",
    Price: "السعر",
    "Total Price": "السعر الكلي",
    "Add voucher discount": "إضافة خصم القسيمة",
    "Apply Code": "تطبيق الكود",
    Discount: "خصم",
    OFF: "خصم",
    "Code:": "الكود:",
    Applied: "تم التطبيق",
    "Order Summary": "ملخص الطلب",
    "Process To Checkout": "التوجه لإتمام الشراء",
    "Continue shopping": "متابعة التسوق",
    "Item is Not Available Currently": "العنصر غير متوفر حاليا",
    "Please Add the Baby Name": "الرجاء إضافة اسم الطفل",
    "Please Select a Color": "اختر لونا من فضلك",
    "Please Select a Size": "الرجاء تحديد الحجم",
    ThankYou: "شكرًا لك على الطلب من متجرنا",
  },
  en: {
    "View All Products": "View All Products",
    Home: "Home",
    Shop: "Shop",
    Product: "Product",
    Support: "Support",
    "Best Sellers": "Best Sellers",
    "On Sale": "On Sale",
    "New Arrivals": "New Arrivals",
    "About Us": "About Us",
    "Contact Us": "Contact Us",
    "Shipping & Delivery": "Shipping & Delivery",
    "Exchange & Return": "Exchange & Return",
    FAQs: "FAQs",
    "Wholesale/Become a distributor": "Wholesale/Become a distributor",
    Login: "Login",
    "Don’t have an account?": "Don’t have an account?",
    Register: "Register",
    Logout: "Logout",
    Welcome: " Welcome",
    Back: "Back",
    Sales: "Sales",
    "View All": "View All",
    Recommended: "Recommended",
    Newletter: "Newletter",
    "Sign up for our newsletter": "Sign up for our newsletter",
    Shipping: "Shipping",
    Wholesale: "Wholesale",
    "Mail:": "Mail:",
    "Phone:": "Phone:",
    "best sellers": "best sellers",
    "on sale": "on sale",
    "new arrivals": "new arrivals",
    Collections: "Collections",
    Categories: "Categories",
    Colors: "Colors",
    Size: "Size",
    Quantity: "Quantity",
    "Product is already in your cart": "Product is already in your cart",
    "Similar Product is alreday in your cart":
      "Similar Product is alreday in your cart",
    "Login To Add To Cart": "Login To Add To Cart",
    Category: "Category",
    "Share Products": "Share Products",
    Reviews: "Reviews",
    Comments: "Comments",
    "Write Reviews": "Write Reviews",
    "Login to review this product": "Login to review this product",
    "You already reviewed this product": "You already reviewed this product",
    "Leave A Review": "Leave A Review",
    "Your comment *": "Your comment *",
    "Submit Reviews": "Submit Reviews",
    "You'll love this too": "You'll love this too",
    "Add To Cart": "Add To Cart",
    Add: "Add",
    Remove: "Remove",
    "To Cart": "To Cart",
    "From Cart": "From Cart",
    "Please fill the order tracking number!":
      "Please fill the order tracking number!",
    "An error occured! Please try again.":
      "An error occured! Please try again.",
    "Order Tracking": "Order Tracking",
    "To track your order please enter your Order ID in the box below and press the Track button. This was given to you on your receipt and in the confirmation email you should have received.":
      "To track your order please enter your Order ID in the box below and press the Track button. This was given to you on your receipt and in the confirmation email you should have received.",
    "Tracking Number *": "Tracking Number *",
    "Tracking Order": "Tracking Order",
    "Order Created": "Order Created",
    "Order Paid": "Order Paid",
    "Order Shipped": "Order Shipped",
    "Order Delivered": "Order Delivered",
    "Order Canceled": "Order Canceled",
    Information: "Information",
    "Delivery Address": "Delivery Address",
    "Your Order": "Your Order",
    "No product in cart": "No product in cart",
    Subtotal: "Subtotal",
    "Please add your address": "Please add your address",
    Discounts: "Discounts",
    Total: "Total",
    "Confirm and Pay": "Confirm and Pay",
    "UPay Checkout": "Pay Full Amount Once.",
    "Tabby Checkout": "Pay in 4. No interest, No fees.",
    "Tabby Error Not Available":
      "Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.",
    "Tabby Error Amount Too High":
      "This purchase is above your current spending limit with Tabby, try a smaller cart or use another payment method",
    "Tabby Error Amount Too Low":
      "The purchase amount is below the minimum amount required to use Tabby, try adding more items or use another payment method",
    "Tabby Cancelation":
      "You aborted the payment. Please retry or choose another payment method.",
    "Tabby Failure":
      "Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order",
    "Use any Card": "Use any card.",
    "You will be redirect to U-Pay secure payment Gateway.":
      "You will be redirect to U-Pay secure payment Gateway.",
    redirectUPay: "You will be redirect to U-Pay secure payment Gateway.",
    redirectTabby: "You will be redirect to Tabby secure payment Gateway.",
    "Shopping Cart": "Shopping Cart",

    Note: "Note",

    Coupon: "Coupon",

    "View cart": "View cart",
    "Check Out": "Check Out",
    "Or continue shopping": "Or continue shopping",
    "Estimate shipping rates": "Estimate shipping rates",
    Country: "Country",
    "Shipping Fees:": "Shipping Fees:",
    Close: "Close",
    "Add A Coupon Code": "Add A Coupon Code",
    "Enter Code": "Enter Code",
    "Discount code": "Discount code",
    Apply: "Apply",
    Cancel: "Cancel",
    "Add special instructions for your order...":
      "Add special instructions for your order...",
    Save: "Save",
    "Shopping cart": "Shopping cart",
    Products: "Products",
    Price: "Price",
    "Total Price": "Total Price",
    "Add voucher discount": "Add voucher discount",
    "Apply Code": "Apply Code",
    Discount: "Discount",
    OFF: "OFF",
    "Code:": "Code:",
    Applied: "Applied",
    "Order Summary": "Order Summary",

    "Process To Checkout": "Process To Checkout",
    "Continue shopping": "Continue shopping",
    "Item is Not Available Currently": "Item is Not Available Currently",
    "Please Add the Baby Name": "Please Add the Baby Name",
    "Please Select a Color": "Please Select a Color",
    "Please Select a Size": "Please Select a Size",
    ThankYou: "Thank you for ordering from our store",
  },
};

interface LanguagesState {
  languages: any[];
  lang: string;
}

type LanguagesAction =
  | { type: "UPDATE_LANGUAGE"; payload: any }
  | { type: "LOAD_LANGUAGE" };

interface LanguagesLanguagesContextProps {
  langState: LanguagesState;
  updateLanguage: (language: any) => void;
  t: (toTranslate: any) => any;
}

const LanguagesContext = createContext<
  LanguagesLanguagesContextProps | undefined
>(undefined);

const LanguagesReducer = (
  state: LanguagesState,
  action: LanguagesAction
): LanguagesState => {
  const { readFromLocalStorage, saveToLocalStorage } = useLocalStorage();
  switch (action.type) {
    case "UPDATE_LANGUAGE":
      saveToLocalStorage(LocalStorageKeys.LANGUAGE, action.payload || "en");
      return {
        ...state,
        lang: action.payload || "en",
      };
    case "LOAD_LANGUAGE":
      let lang_ = readFromLocalStorage(LocalStorageKeys.LANGUAGE);
      return {
        ...state,
        lang: lang_ || "en",
      };
    default:
      return state;
  }
};

export const LanguagesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [langState, dispatch] = useReducer(LanguagesReducer, {
    languages: ["en", "ar"],
    lang: "en",
  });

  const t = (translationKey: string) => {
    return translations[langState.lang]?.[translationKey] || translationKey;
  };

  const updateLanguage = (item: any) => {
    dispatch({ type: "UPDATE_LANGUAGE", payload: item });
  };

  const loadLanguage = () => {
    dispatch({ type: "LOAD_LANGUAGE" });
  };

  useEffect(() => {
    loadLanguage();
  }, []);

  return (
    <LanguagesContext.Provider
      value={{
        langState,
        updateLanguage,
        t,
      }}
    >
      {children}
    </LanguagesContext.Provider>
  );
};

export const useLanguages = () => {
  const context = useContext(LanguagesContext);
  if (!context) {
    throw new Error("useLanguages must be used within a LanguagesProvider");
  }
  return context;
};
