//@ts-nocheck
import React, { useState, useEffect } from "react";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import * as Icon from "@phosphor-icons/react";
import { readFromLocalStorage, LocalStorageKeys } from "@/hooks/localStorage";

const Faqs = () => {
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    const shop_ = readFromLocalStorage(LocalStorageKeys.SHOP);
    setFaqData(shop_?.faqs || []);
  }, []);

  const [activeTab, setActiveTab] = useState("");
  const [activeQuestion, setActiveQuestion] = useState(undefined);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Extract unique categories from the FAQ data
    const uniqueCategories = [...new Set(faqData.map((faq) => faq.category))];
    setCategories(uniqueCategories);
    // Set the first category as active by default
    setActiveTab(uniqueCategories[0]);
  }, [faqData]);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setActiveQuestion(undefined); // Reset active question when changing tabs
  };

  const handleActiveQuestion = (question) => {
    setActiveQuestion((prevQuestion) =>
      prevQuestion === question ? undefined : question
    );
  };

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="FAQs" subHeading="FAQs" />
      </div>
      <div className="faqs-block md:py-20 py-10">
        <div className="container">
          <div className="flex justify-between">
            <div className="left w-1/4">
              <div className="menu-tab flex flex-col gap-5">
                {categories.map((category, index) => (
                  <div
                    key={index}
                    className={`tab-item inline-block w-fit heading6 has-line-before text-secondary2 hover:text-black duration-300 ${
                      activeTab === category ? "active" : ""
                    }`}
                    onClick={() => handleActiveTab(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>
            <div className="right w-2/3">
              {faqData
                .filter((faq) => faq.category === activeTab)
                .map((faq, index) => (
                  <div
                    key={index}
                    className={`tab-question flex flex-col gap-5 active`}
                  >
                    <div
                      className={`question-item px-7 py-5 rounded-[20px] overflow-hidden border border-line cursor-pointer ${
                        activeQuestion === faq.question ? "open" : ""
                      }`}
                      style={{ marginBottom: 20 }}
                      onClick={() => handleActiveQuestion(faq.question)}
                    >
                      <div className="heading flex items-center justify-between gap-6">
                        <div className="heading6">{faq.question}</div>
                        <Icon.CaretRight size={24} />
                      </div>
                      <div
                        className="content body1 text-secondary"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      ></div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faqs;
