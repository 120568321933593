import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const MainSlider = ({ announcements }: { announcements: any[] }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 100,
    draggable: true,
    useTransform: false,
  };

  return (
    <>
      <div className="slider-block toys-kid  w-full  mt-[30px]">
        <div className="container h-full aspect-[2/1]">
          <div className="slider-main h-full w-full">
            <Slider {...settings} className="slider-toys-kid w-full h-full">
              {announcements?.map((announcement, index) => (
                <div
                  key={index}
                  className="slider-item bg-[#EBFCF5] h-full w-full relative flex max-sm:flex-col-reverse items-center lg:rounded-[40px] rounded-xl overflow-hidden"
                >
                  <img
                    src={announcement}
                    width={2000}
                    height={1000}
                    alt="bg"
                    className="absolute top-0 left-0 w-full h-full object-fit "
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSlider;
