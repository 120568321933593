//@ts-nocheck
import React, { useEffect, useState } from "react";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
// import Link from "next/link";
import { Link } from "react-router-dom";
import * as Icon from "@phosphor-icons/react";
import { usePrices } from "@/context/PricesContext";
import { useLanguages } from "@/context/LanguageContext";

interface Props {
  props: string;
}

const TopNavThree: React.FC<Props> = ({ props }) => {
  const { updateLanguage, langState } = useLanguages();
  const [shop, setShop] = useState({});
  useEffect(() => {
    const shop_ = readFromLocalStorage(LocalStorageKeys.SHOP);
    setShop(shop_);
  }, []);

  const { pricesState, updateCurrency } = usePrices();
  return (
    <>
      <div
        className={`top-nav md:h-[30px] h-[30px] border-b border-line ${props}`}
        style={{ backgroundColor: "#ddcdbd" }}
      >
        <div className="container mx-auto h-full">
          <div className="top-nav-main flex justify-between h-full">
            <div className="left-content flex items-center"></div>
            <div className="right-content flex items-center gap-5">
              <div className="choose-language flex items-center gap-1.5">
                <select
                  name="language"
                  id="chooseLanguage"
                  className="caption2"
                  style={{ backgroundColor: "#ddcdbd" }}
                  onChange={(ev) => {
                    updateLanguage(ev.target.value);
                  }}
                >
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
                <Icon.CaretDown size={12} color="#1F1F1F" />
              </div>
              <div className="choose-currency flex items-center gap-1.5 pr-5 border-r border-line">
                <select
                  name="currency"
                  id="chooseCurrency"
                  className="caption2"
                  style={{ backgroundColor: "#ddcdbd" }}
                  value={pricesState.selectedCurrency || "KD"}
                  onChange={(ev) => {
                    updateCurrency(ev.target.value);
                  }}
                >
                  {pricesState?.currencies?.map((currency) => (
                    <option
                      key={currency?.name?.[langState?.lang]}
                      value={currency?.name?.[langState?.lang]}
                    >
                      {currency?.name?.[langState?.lang]}
                    </option>
                  ))}
                </select>
                <Icon.CaretDown size={12} color="#1F1F1F" />
              </div>
              {shop?.socialMedia?.instagram ? (
                <Link to={shop?.socialMedia?.instagram} target="_blank">
                  <i className="icon-instagram text-black"></i>
                </Link>
              ) : (
                <></>
              )}
              {shop?.socialMedia?.facebook ? (
                <Link to={shop?.socialMedia?.facebook} target="_blank">
                  <i className="icon-facebook text-black"></i>
                </Link>
              ) : (
                <></>
              )}

              {shop?.socialMedia?.youtube ? (
                <Link to={shop?.socialMedia?.youtube} target="_blank">
                  <i className="icon-youtube text-black"></i>
                </Link>
              ) : (
                <></>
              )}
              {shop?.socialMedia?.tiktok ? (
                <Link to={shop?.socialMedia?.tiktok || ""} target="_blank">
                  <img
                    src="/images/icons/tiktok.svg"
                    alt="tiktok"
                    width={16}
                    height={16}
                  />
                </Link>
              ) : (
                <></>
              )}
              {shop?.socialMedia?.pinterest ? (
                <Link to={shop?.socialMedia?.pinterest} target="_blank">
                  <i className="icon-pinterest text-black"></i>
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNavThree;
