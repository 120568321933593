//@ts-nocheck
// PricesContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useReducer,
  useEffect,
} from "react";
import useLocalStorage, { LocalStorageKeys } from "@/hooks/localStorage";
import axios, { endpoints } from "@/utils/axios";

interface PricesState {
  currencies: any[];
  selectedCurrency: any;
}

type PricesAction =
  | { type: "UPDATE_CURRENCY"; payload: any }
  | { type: "LOAD_CURRENCY" }
  | { type: "LOAD_CURRENCIES" };

interface PricesContextProps {
  pricesState: PricesState;
  updateCurrency: (currency: any) => void;
  formatPrice: (price: any, currency?: string) => String;
  calaculateTotalDeliveryRate: (
    countryCode: any,
    postCode: any,
    totalWeight: any,
    totalPrice: any,
    items: any
  ) => any;

  calculateTotalDeliveryRateByCountry: (totalWeight: any, country: any) => any;
}

const PricesContext = createContext<PricesContextProps | undefined>(undefined);

const PricesReducer = (
  state: PricesState,
  action: PricesAction
): PricesState => {
  const { readFromLocalStorage, saveToLocalStorage } = useLocalStorage();
  switch (action.type) {
    case "UPDATE_CURRENCY":
      saveToLocalStorage(LocalStorageKeys.CURRENCY, action.payload || "KD");
      return {
        ...state,
        selectedCurrency: action.payload || "KD",
      };
    case "LOAD_CURRENCIES":
      let shopInfo = readFromLocalStorage(LocalStorageKeys.SHOP);
      return {
        ...state,
        // @ts-ignore
        currencies: shopInfo?.currencies || [
          {
            name: { en: "KD", ar: "دك" },
            value: 1,
          },
        ],
      };
    case "LOAD_CURRENCY":
      let currency_ = readFromLocalStorage(LocalStorageKeys.CURRENCY);
      return {
        ...state,
        selectedCurrency: currency_ || "KD",
      };
    default:
      return state;
  }
};

export const PricesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pricesState, dispatch] = useReducer(PricesReducer, {
    currencies: [
      {
        name: { en: "KD", ar: "دك" },
        value: 1,
      },
    ],
    selectedCurrency: "KD",
  });

  const updateCurrency = (item: any) => {
    dispatch({ type: "UPDATE_CURRENCY", payload: item });
  };

  const loadCurrency = () => {
    dispatch({ type: "LOAD_CURRENCY" });
  };

  const loadCurrencies = () => {
    dispatch({ type: "LOAD_CURRENCIES" });
  };

  useEffect(() => {
    loadCurrency();
  }, []);
  useEffect(() => {
    loadCurrencies();
  }, []);
  const formatPrice = (price: any, currency = "KD") => {
    const value =
      (price *
        pricesState?.currencies?.find((x) => x.name?.en === currency)?.value) /
      pricesState?.currencies?.find(
        (x) => x.name?.en === pricesState?.selectedCurrency
      )?.value;

    if (isNaN(value)) {
      return "";
    }

    return `${pricesState.selectedCurrency} ${value.toFixed(2)}`;
  };

  const calaculateTotalDeliveryRate = async (
    countryCode,
    postCode,
    totalWeight,
    totalPrice,
    items,
    city = ""
  ) => {
    if (
      !countryCode ||
      !postCode ||
      postCode?.length < 4 ||
      !totalWeight ||
      !totalPrice ||
      !items.length ||
      city?.length < 3
    ) {
      return 0;
    }
    if (countryCode === "KW") {
      return 2;
    }
    const aramexResp = await axios.post(endpoints.delivery.rates, {
      countryCode,
      postCode,
      totalWeight,
      totalPrice,
      items: items?.map((x) => ({
        quantity: x.purchaseQuantity,
        weight: x.weight,
      })),
      city,
    });

    if (aramexResp.status === 200) {
      return aramexResp.data;
    }
  };

  const calculateTotalDeliveryRateByCountry = (totalWeight, country) => {
    let delRate = 0;
    if (!totalWeight || !country) {
      delRate = 0;
      return 0;
    }
    if (totalWeight < 0.5) {
      delRate = country?.half_1;
    } else if (totalWeight < 10) {
      delRate =
        country?.half_1 + ((totalWeight - 0.5) / 0.5) * country?.half_extra;
    } else if (totalWeight < 15) {
      delRate =
        country?.half_1 +
        (9.5 / 0.5) * country?.half_extra +
        ((totalWeight - 10) / 0.5) * country?.half_10;
    } else {
      delRate =
        country?.half_1 +
        (9.5 / 0.5) * country?.half_extra +
        2.5 * country?.half_10 +
        ((totalWeight - 15) / 0.5) * country?.half_15;
    }

    return delRate * 1.25;
  };

  return (
    <PricesContext.Provider
      value={{
        pricesState,
        updateCurrency,
        formatPrice,
        calaculateTotalDeliveryRate,
        calculateTotalDeliveryRateByCountry,
      }}
    >
      {children}
    </PricesContext.Provider>
  );
};

export const usePrices = () => {
  const context = useContext(PricesContext);
  if (!context) {
    throw new Error("usePrices must be used within a PricesProvider");
  }
  return context;
};
