import React, { useState, useEffect } from "react";

import { useWindowSize } from "@/hooks/windowSize";

interface SplashScreenProps {
  finishLoading: Function;
}

//
const SplashScreen: React.FC<SplashScreenProps> = ({ finishLoading }) => {
  const { windowSize } = useWindowSize();

  useEffect(() => {
    const timeout = setTimeout(() => {
      finishLoading();
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      {/* <Image
        id='logo'
        src='/images/logo/logo_full.png'
        alt=''
        width={200}
        height={100}
      /> */}
      {windowSize?.width && windowSize?.height && (
        <img
          id="logo"
          src="/images/logo/logo_animated.gif"
          alt=""
          width={800}
          height={800}
        />
      )}
    </div>
  );
};

export default SplashScreen;
