import axios from "axios";

export const HOST_API = process.env.REACT_APP_HOST_API;

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

// ----------------------------------------------------------------------

// export const fetcher = async (args) => {
//   const [url, config] = Array.isArray(args) ? args : [args];

//   const res = await axiosInstance.get(url, { ...config });

//   return res.data;
// };

export const fetcher = async (url, config = {}) => {
  // const [url, config] = queryKey;
  const res = await axiosInstance.get(url, config);
  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: "/auth/user/validate",
    login: "/auth/user/login",
    register: "/auth/user/register",
    forgotPassword: "/auth/user/forgotPassword",
    newPassword: "/auth/user/newPassword",
  },
  menu: {
    all: "/menu/all",
    shop: "/menu/shop",
    featured: "/menu/featured",
  },
  review: {
    add: "/reviews",
  },
  shoppingCart: {
    get: "/cart",
    add: "/cart",
    remove: "/cart",
    increase: "/cart/increase",
    decrease: "/cart/decrease",
    coupon: "/cart/coupon",
    submit: "/cart/submit",
    track: "/cart/track",
  },
  delivery: {
    cities: "/delivery/cities",
    rates: "/delivery/rate",
  },
  log: {
    add: "/log",
  },
  visit: {
    add: "/visit",
  },
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("accessToken", accessToken);

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    // tokenExpired(exp);
  } else {
    sessionStorage.removeItem("accessToken");

    delete axiosInstance.defaults.headers.common.Authorization;
  }
};
