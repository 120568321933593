//@ts-nocheck
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import { Formik, Form, Field } from "formik";
import axios, { endpoints } from "@/utils/axios";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import { useUser } from "@/context/UserContext";

const Login = () => {
  const { saveUserToLocalStorage } = useUser();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const initialValues = {
    email: "",
    password: "",
  };

  // Handling form submission
  const handleSubmit = async (values) => {
    setErrorMessage(null);
    try {
      const resp = await axios.post(endpoints.auth.login, values);

      if (resp.status === 200) {
        let userInfo = { ...resp?.data?.user, token: resp?.data?.token };

        saveUserToLocalStorage(userInfo);
        navigate(`/`);
      } else {
        setErrorMessage("Something went wrong! Please try again.");
      }
    } catch (error) {
      if (error?.includes?.("E11000")) {
        setErrorMessage("User already exists! Login or Reset your password");
      } else if (error?.includes?.("Invalid credentials")) {
        setErrorMessage(error);
      } else {
        setErrorMessage("Something went wrong! Please try again.");
      }
    } finally {
    }
  };

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="Login" subHeading="Login" />
      </div>
      <div className="login-block md:py-20 py-10">
        <div className="container">
          <div className="content-main flex gap-y-8 max-md:flex-col">
            <div className="left md:w-1/2 w-full lg:pr-[60px] md:pr-[40px] md:border-r border-line">
              <div className="heading4">Login</div>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {() => (
                  <Form className="md:mt-7 mt-4">
                    <div className="email ">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email address *"
                        required
                      />
                    </div>
                    <div className="pass mt-5">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password *"
                        required
                      />
                    </div>
                    <div className="flex items-center justify-between mt-5">
                      <div className="flex items-center" />

                      <Link
                        to={"/forgot-password"}
                        className="font-semibold hover:underline"
                      >
                        Forgot Your Password?
                      </Link>
                    </div>
                    {errorMessage && (
                      <label className="cursor-pointer text-red">
                        {errorMessage}
                      </label>
                    )}
                    <div className="block-button md:mt-7 mt-4">
                      <button
                        type="submit"
                        className="button-main"
                        style={{ backgroundColor: "#977052" }}
                      >
                        Login
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="right md:w-1/2 w-full lg:pl-[60px] md:pl-[40px] flex items-center">
              <div className="text-content">
                <div className="heading4">New Customer</div>
                <div className="mt-2 text-secondary">
                  Be part of our growing family of new customers! Join us today
                  and unlock a world of exclusive benefits, offers, and
                  personalized experiences.
                </div>
                <div className="block-button md:mt-7 mt-4">
                  <Link to={"/register"} className="button-main">
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
