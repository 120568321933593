import { useEffect } from "react";
import { useLanguages } from "@/context/LanguageContext";

interface Props {
  amount: number | null;
}

const TabbyComponent: React.FC<Props> = ({ amount }) => {
  const { langState } = useLanguages();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.tabby.ai/tabby-promo.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      //@ts-ignore
      new TabbyPromo({
        selector: "#tabby",
        currency: "KWD",
        price: amount?.toFixed(2),
        installmentsCount: 4,
        lang: langState.lang,
        // source: "product",
        // publicKey: "pk_test_01907c25-4ffb-0457-1986-fb11aa0f4f29",
        publicKey: "pk_01907c25-4ffb-0457-1986-fb107c53d929",
        merchantCode: "Slothkwt",
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [amount, langState.lang]);

  return <div id="tabby" style={{ marginTop: 20 }}></div>;
};
export default TabbyComponent;
