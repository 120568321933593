import { useQuery } from "@tanstack/react-query";
import { useMemo, useEffect } from "react";
import useLocalStorage, { LocalStorageKeys } from "@/hooks/localStorage";
import { fetcher, endpoints } from "@/utils/axios";

// ----------------------------------------------------------------------

export function useGetShop() {
  const URL = endpoints.menu.shop;
  const { saveToLocalStorage } = useLocalStorage();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [URL],
    queryFn: () => fetcher(URL),
  });
  useEffect(() => {
    if (data) {
      saveToLocalStorage(LocalStorageKeys.SHOP, data);
    }
  }, [data]);

  const memoizedValue = useMemo(
    () => ({
      shop: data || [],
      shopLoading: isLoading,
      shopError: error,
      shopRefetch: refetch,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}
