export const LocalStorageKeys = {
  SHOP: "@SLOTH_SHOP",
  USER: "@SLOTH_USER",
  MENU: "@SLOTH_MENU",
  FEATURED: "@SLOTH_FEATURED",
  CURRENCY: "@SLOTH_CURRENCY",
  CART: "@SLOTH_CART",
  LANGUAGE: "@SLOTH_LANGUAGE",
  VISIST: "@Qh6TsgxUO03",
};

export function saveToLocalStorage(key, value) {
  localStorage?.setItem(
    key,
    typeof value === "string" ? value : JSON.stringify(value)
  );
}

export function readFromLocalStorage(key) {
  if (typeof window !== "undefined") {
    let value = localStorage?.getItem(key);
    try {
      if (value) {
        try {
          value = JSON.parse(value);
        } catch {
          value = value ?? null;
        }
      }
    } catch (er) {}
    return value;
  }
  return null;
}
export function clearDataFromLocalStorage(key) {
  localStorage.removeItem(key);
}
export default function useLocalStorage() {
  function saveToLocalStorage(key, value) {
    localStorage?.setItem(
      key,
      typeof value === "string" ? value : JSON.stringify(value)
    );
  }

  function readFromLocalStorage(key) {
    if (typeof window !== "undefined") {
      let value = localStorage?.getItem(key);
      try {
        if (value) {
          try {
            value = JSON.parse(value);
          } catch {
            value = value ?? null;
          }
        }
      } catch (er) {}
      return value;
    }
    return null;
  }

  function clearDataFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  return {
    saveToLocalStorage,
    readFromLocalStorage,
    clearDataFromLocalStorage,
  };
}
