//@ts-nocheck
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icon from "@phosphor-icons/react";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
import { usePrices } from "@/context/PricesContext";
import { useLanguages } from "@/context/LanguageContext";
const Footer = () => {
  const { updateLanguage, langState, t } = useLanguages();
  const { pricesState, updateCurrency } = usePrices();
  const [shop, setShop] = useState({});
  useEffect(() => {
    const shop_ = readFromLocalStorage(LocalStorageKeys.SHOP);
    setShop(shop_);
  }, []);

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const menu_ = readFromLocalStorage(LocalStorageKeys.MENU);
    setMenu(menu_ ?? []);
  }, []);

  return (
    <>
      <div id="footer" className="footer" style={{ marginTop: 80 }}>
        <div className="footer-main bg-surface">
          <div className="container">
            <div className="content-footer py-[60px] flex justify-between flex-wrap gap-y-8">
              <div className="company-infor basis-1/4 max-lg:basis-full pr-7">
                <Link to={"/"} className="logo">
                  <div className="heading4">
                    <img
                      src={"/images/logo/logo_full.png"}
                      width={150}
                      height={75}
                      alt="Sloth"
                      className="object-cover"
                    />
                  </div>
                </Link>
                <div className="flex gap-3 mt-3">
                  <div className="flex flex-col ">
                    {shop?.socialMedia?.email ? (
                      <span className="text-button">Mail:</span>
                    ) : (
                      <></>
                    )}
                    {shop?.socialMedia?.phoneNumber ? (
                      <span className="text-button mt-3">Phone:</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-col ">
                    {shop?.socialMedia?.email ? (
                      <span className="">{shop?.socialMedia?.email}</span>
                    ) : (
                      <></>
                    )}
                    {shop?.socialMedia?.phoneNumber ? (
                      <span className="mt-3">
                        {shop?.socialMedia?.phoneNumber}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-content flex flex-wrap gap-y-8 basis-3/4 max-lg:basis-full">
                <div className="list-nav flex justify-between basis-2/3 max-md:basis-full gap-4">
                  <div className="item flex flex-col basis-1/2 ">
                    <div className="text-button-uppercase pb-3">
                      {t("Shop")}
                    </div>

                    {menu?.slice(0, 4)?.map((category) => (
                      <Link
                        key={category?._id}
                        className="caption1 has-line-before duration-300 w-fit pt-2"
                        to={`/shop?category=${category?._id}`}
                      >
                        {category.name?.[langState?.lang]}
                      </Link>
                    ))}
                    <Link
                      className="caption1 has-line-before duration-300 w-fit pt-2"
                      to={"/shop"}
                    >
                      {t("View All")}
                    </Link>
                  </div>
                  <div className="item flex flex-col basis-1/2 ">
                    <div className="text-button-uppercase pb-3">
                      {t("Support")}
                    </div>
                    <Link
                      className="caption1 has-line-before duration-300 w-fit"
                      to={"/about"}
                    >
                      {t("About Us")}
                    </Link>
                    <Link
                      className="caption1 has-line-before duration-300 w-fit pt-2"
                      to={"/contact"}
                    >
                      {t("Contact Us")}
                    </Link>
                    <Link
                      className="caption1 has-line-before duration-300 w-fit pt-2"
                      to={"/shipping"}
                    >
                      {t("Shipping")}
                    </Link>
                    <Link
                      className="caption1 has-line-before duration-300 w-fit pt-2"
                      to={"/faqs"}
                    >
                      {t("FAQs")}
                    </Link>
                    <Link
                      className="caption1 has-line-before duration-300 w-fit pt-2"
                      to={"/wholesale"}
                    >
                      {t("Wholesale")}
                    </Link>
                  </div>
                </div>
                <div className="newsletter basis-1/3 pl-7 max-md:basis-full max-md:pl-0">
                  <div className="text-button-uppercase">{t("Newletter")}</div>
                  <div className="caption1 mt-3">
                    {t("Sign up for our newsletter")}
                  </div>
                  <div className="input-block w-full h-[52px] mt-4">
                    <form className="w-full h-full relative" action="post">
                      <input
                        type="email"
                        placeholder="Enter your e-mail"
                        className="caption1 w-full h-full pl-4 pr-14 rounded-xl border border-line"
                        required
                      />
                      <button className="w-[44px] h-[44px] bg-black flex items-center justify-center rounded-xl absolute top-1 right-1">
                        <Icon.ArrowRight size={24} color="#fff" />
                      </button>
                    </form>
                  </div>
                  <div className="list-social flex items-center gap-6 mt-4">
                    {shop?.socialMedia?.instagram ? (
                      <Link to={shop?.socialMedia?.instagram} target="_blank">
                        <i className="icon-instagram text-black"></i>
                      </Link>
                    ) : (
                      <></>
                    )}
                    {shop?.socialMedia?.facebook ? (
                      <Link to={shop?.socialMedia?.facebook} target="_blank">
                        <i className="icon-facebook text-black"></i>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {shop?.socialMedia?.youtube ? (
                      <Link to={shop?.socialMedia?.youtube} target="_blank">
                        <i className="icon-youtube text-black"></i>
                      </Link>
                    ) : (
                      <></>
                    )}
                    {shop?.socialMedia?.tiktok ? (
                      <Link
                        to={shop?.socialMedia?.tiktok || ""}
                        target="_blank"
                      >
                        <img
                          src="/images/icons/tiktok.svg"
                          alt="tiktok"
                          width={16}
                          height={16}
                        />
                      </Link>
                    ) : (
                      <></>
                    )}
                    {shop?.socialMedia?.pinterest ? (
                      <Link to={shop?.socialMedia?.pinterest} target="_blank">
                        <i className="icon-pinterest text-black"></i>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom py-3 flex items-center justify-between gap-5 max-lg:justify-center max-lg:flex-col border-t border-line">
              <div className="left flex items-center gap-8">
                <div className="copyright caption1 text-secondary">
                  ©2024 Sloth. All Rights Reserved.
                </div>
                <div className="select-block flex items-center gap-5 max-md:hidden">
                  <div className="choose-language flex items-center gap-1.5">
                    <select
                      name="language"
                      id="chooseLanguageFooter"
                      className="caption2 bg-transparent"
                      onChange={(ev) => {
                        updateLanguage(ev.target.value);
                      }}
                    >
                      <option value="en">English</option>
                      <option value="ar">Arabic</option>
                    </select>
                    <Icon.CaretDown size={12} color="#1F1F1F" />
                  </div>
                  <div className="choose-currency flex items-center gap-1.5">
                    <select
                      name="currency"
                      id="chooseCurrencyFooter"
                      className="caption2 bg-transparent"
                      value={pricesState.selectedCurrency || "KD"}
                      onChange={(ev) => {
                        updateCurrency(ev.target.value);
                      }}
                    >
                      {pricesState?.currencies?.map((currency) => (
                        <option
                          key={currency?.name?.[langState?.lang]}
                          value={currency?.name?.[langState?.lang]}
                        >
                          {currency?.name?.[langState?.lang]}
                        </option>
                      ))}
                    </select>
                    <Icon.CaretDown size={12} color="#1F1F1F" />
                  </div>
                </div>
              </div>
              <div className="right flex items-center gap-2">
                <div className="caption1 text-secondary">Payment:</div>
                <div className="payment-img">
                  <img
                    src={"/images/payment/google-pay.png"}
                    width={500}
                    height={500}
                    alt={"payment"}
                    className="w-9"
                  />
                </div>
                <div className="payment-img">
                  <img
                    src={"/images/payment/spay.png"}
                    width={500}
                    height={500}
                    alt={"payment"}
                    className="w-9"
                  />
                </div>
                <div className="payment-img">
                  <img
                    src={"/images/payment/apple-pay.png"}
                    width={500}
                    height={500}
                    alt={"payment"}
                    className="w-9"
                  />
                </div>
                <div className="payment-img">
                  <img
                    src={"/images/payment/credit_card.png"}
                    width={500}
                    height={500}
                    alt={"payment"}
                    className="w-9"
                  />
                </div>
                <div className="payment-img">
                  <img
                    src={"/images/payment/knet.png"}
                    width={500}
                    height={500}
                    alt={"payment"}
                    className="w-9"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
