"use client";

// WishlistContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useReducer,
  useEffect,
} from "react";

interface WishlistState {
  wishlistArray: String[];
}

type WishlistAction =
  | { type: "ADD_TO_WISHLIST"; payload: any }
  | { type: "REMOVE_FROM_WISHLIST"; payload: string }
  | { type: "LOAD_WISHLIST"; payload: any[] };

interface WishlistContextProps {
  wishlistState: WishlistState;
  addToWishlist: (itemId: any) => void;
  removeFromWishlist: (itemId: string) => void;
  isInWishList: (itemId: string) => boolean;
}

const WishlistContext = createContext<WishlistContextProps | undefined>(
  undefined
);

const WishlistReducer = (
  state: WishlistState,
  action: WishlistAction
): WishlistState => {
  switch (action.type) {
    case "ADD_TO_WISHLIST":
      return {
        ...state,
        wishlistArray: [...state.wishlistArray, action.payload],
      };
    case "REMOVE_FROM_WISHLIST":
      return {
        ...state,
        wishlistArray: state.wishlistArray.filter(
          //@ts-ignore
          (item) => item?._id !== action.payload
        ),
      };
    case "LOAD_WISHLIST":
      return {
        ...state,
        wishlistArray: action.payload,
      };
    default:
      return state;
  }
};

export const WishlistProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [wishlistState, dispatch] = useReducer(WishlistReducer, {
    wishlistArray: [],
  });

  const addToWishlist = (item: any) => {
    dispatch({ type: "ADD_TO_WISHLIST", payload: item });
  };

  const removeFromWishlist = (itemId: string) => {
    dispatch({ type: "REMOVE_FROM_WISHLIST", payload: itemId });
  };

  const isInWishList = (itemId: string) =>
    //@ts-ignore
    !!wishlistState.wishlistArray.find((x) => x?._id === itemId);

  return (
    <WishlistContext.Provider
      value={{ wishlistState, addToWishlist, removeFromWishlist, isInWishList }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  const context = useContext(WishlistContext);
  if (!context) {
    throw new Error("useWishlist must be used within a WishlistProvider");
  }
  return context;
};
