//@ts-nocheck
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Icon from "@phosphor-icons/react";
import { useModalCartContext } from "@/context/ModalCartContext";
import { useCart } from "@/context/CartContext";
import { useUser } from "@/context/UserContext";
import { usePrices } from "@/context/PricesContext";
import { useCities } from "@/api/deliveryFees";
import { useLanguages } from "@/context/LanguageContext";
import TabbyComponent from "@/components/Tabby/TabbyPromo";

const ModalCart = () => {
  const { langState, t } = useLanguages();
  const { cities } = useCities();
  const [selectedArea, setSelectedArea] = useState(cities?.[0]);
  useEffect(() => {
    setSelectedArea(cities?.[0]);
  }, [cities]);
  const { userState } = useUser();
  const user = userState.user || null;
  const {
    shoppingCart,
    cartTotal,
    currency,
    discount,
    notes,
    cartWeight,
    validateCoupon,
    decreaseQuantity,
    addNote,
    clearCart,
  } = useCart();
  const { formatPrice, calculateTotalDeliveryRateByCountry } = usePrices();

  const [couponCode, setCouponCode] = useState("");
  const [note, setNote] = useState(notes);
  const handleValidateCoupon = () => {
    validateCoupon(couponCode);
    setActiveTab("");
  };

  const [activeTab, setActiveTab] = useState<string | undefined>("");
  const { isModalOpen, closeModalCart } = useModalCartContext();
  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className={`modal-cart-block`} onClick={closeModalCart}>
        <div
          className={`modal-cart-main flex ${isModalOpen ? "open" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="right cart-block md:w-1/1 w-full py-6 relative overflow-hidden">
            <div className="heading px-6 pb-3 flex items-center justify-between relative">
              <div className="heading5">{t("Shopping Cart")}</div>
              <div
                className="close-btn absolute right-6 top-0 w-6 h-6 rounded-full bg-surface flex items-center justify-center duration-300 cursor-pointer hover:bg-black hover:text-white"
                onClick={closeModalCart}
              >
                <Icon.X size={14} />
              </div>
            </div>

            {shoppingCart?.length < 1 ? (
              <p className="text-button pt-3" style={{ textAlign: "center" }}>
                {t("No product in cart")}
              </p>
            ) : (
              <>
                <div className="list-product px-6">
                  {shoppingCart?.map((product) => (
                    <div
                      key={product.id}
                      className="item py-5 flex items-center justify-between gap-3 border-b border-line"
                    >
                      <div className="infor flex items-center gap-3 w-full">
                        <div className="bg-img w-[100px] aspect-square flex-shrink-0 rounded-lg overflow-hidden">
                          {product.variationImageURL || product.imageURL ? (
                            <img
                              src={
                                product.variationImageURL ?? product.imageURL
                              }
                              width={300}
                              height={300}
                              alt={product.name?.[langState?.lang]}
                              className="w-full h-full"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="w-full">
                          <div className="flex items-center justify-between w-full">
                            <div className="name text-button">
                              {`${product.quantityPurchase} x ${
                                product.name?.[langState?.lang]
                              }`}
                            </div>
                            <div
                              className="remove-cart-btn caption1 font-semibold text-red underline cursor-pointer"
                              onClick={() => {
                                // @ts-ignore
                                decreaseQuantity({
                                  cartProductId: product?.cartProductId,
                                  productId: product?.productId,
                                  color: product?.color || null,
                                  size: product?.size || null,
                                  variations: product?.variations || [],
                                  quantityPurchase: product?.quantityPurchase,
                                });
                              }}
                            >
                              {t("Remove")}
                            </div>
                          </div>

                          <div className="flex items-center justify-between gap-2  w-full">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="flex items-center text-secondary2 capitalize">
                                {`${
                                  product?.isPreOrder
                                    ? `${t("Around")}: ${
                                        product?.availabilityDate?.split(
                                          "T"
                                        )?.[0]
                                      }`
                                    : ""
                                }`}
                              </div>
                              <div className="flex items-center text-secondary2 capitalize">
                                {`${
                                  product.size?.name?.[langState?.lang] ?? ""
                                }${product.size && product.color ? " / " : ""}${
                                  product.color?.name?.[langState?.lang] ?? ""
                                }`}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between  w-full">
                            {product?.variations?.length ? (
                              product?.variations?.map((vari) => (
                                <div className="flex items-center text-secondary2 capitalize">
                                  {`${vari?.textValue || vari?.value}`}
                                </div>
                              ))
                            ) : (
                              <div className="flex items-center text-secondary2 capitalize"></div>
                            )}
                            <div className="product-price text-title">
                              {formatPrice(
                                product.quantityPurchase *
                                  ((product.salePrice || product?.price) +
                                    ((product.color?.additionalPrice || 0) +
                                      (product.size?.additionalPrice || 0) +
                                      (product?.variations || [])?.reduce(
                                        (acc, curr) =>
                                          acc + curr?.value
                                            ? curr.additionalPrice
                                            : 0,
                                        0
                                      ))),
                                product.currency
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="footer-modal bg-white absolute bottom-0 left-0 w-full">
                  <div className="flex items-center justify-center lg:gap-14 gap-8 px-6 py-4 border-b border-line">
                    <div
                      className="item flex items-center gap-3 cursor-pointer"
                      onClick={() => handleActiveTab("note")}
                    >
                      <Icon.NotePencil className="text-xl" />
                      <div className="caption1">{t("Note")}</div>
                    </div>
                    <div
                      className="item flex items-center gap-3 cursor-pointer"
                      onClick={() => handleActiveTab("shipping")}
                    >
                      <Icon.Truck className="text-xl" />
                      <div className="caption1">{t("Shipping")}</div>
                    </div>
                    <div
                      className="item flex items-center gap-3 cursor-pointer"
                      onClick={() => handleActiveTab("coupon")}
                    >
                      <Icon.Tag className="text-xl" />
                      <div className="caption1">{t("Coupon")}</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-6 px-6">
                    <div className="heading6">{t("Subtotal")}</div>
                    <div className="heading6">
                      {formatPrice(cartTotal, currency)}
                    </div>
                  </div>
                  {!!discount && (
                    <div className="flex items-center justify-between pt-6 px-6">
                      <div className="text-title">{t("Discounts")}</div>
                      <div className="text-title">
                        <span className="discount">
                          {formatPrice(discount, currency)}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-between pt-6 px-6">
                    <div className="heading5">{t("Total")}</div>
                    <div className="heading5">
                      {formatPrice(cartTotal + discount, currency)}
                    </div>
                  </div>
                  <div className="block-button text-center p-6">
                    <div className="flex items-center gap-4">
                      <Link
                        to={"/cart"}
                        className="button-main basis-1/2 bg-white border border-black text-black text-center uppercase"
                        onClick={closeModalCart}
                      >
                        <div>{t("View cart")}</div>
                      </Link>
                      <Link
                        to={"/checkout"}
                        className="button-main basis-1/2 text-center uppercase"
                        onClick={closeModalCart}
                      >
                        <div>{t("Check Out")}</div>
                      </Link>
                    </div>
                    <TabbyComponent amount={cartTotal} />
                    <div
                      onClick={closeModalCart}
                      className="text-button-uppercase mt-4 text-center has-line-before cursor-pointer inline-block"
                    >
                      {t("Or continue shopping")}
                    </div>
                  </div>
                  <div
                    className={`tab-item note-block ${
                      activeTab === "note" ? "active" : ""
                    }`}
                  >
                    <div className="px-6 py-4 border-b border-line">
                      <div className="item flex items-center gap-3 cursor-pointer">
                        <Icon.NotePencil className="text-xl" />
                        <div className="caption1">{t("Note")}</div>
                      </div>
                    </div>
                    <div className="form pt-4 px-6">
                      <textarea
                        name="form-note"
                        id="form-note"
                        rows={4}
                        placeholder="Add special instructions for your order..."
                        className="caption1 py-3 px-4 bg-surface border-line rounded-md w-full"
                        value={note}
                        onChange={(ev) => setNote(ev.target.value)}
                      ></textarea>
                    </div>
                    <div className="block-button text-center pt-4 px-6 pb-6">
                      <div
                        className="button-main w-full text-center"
                        onClick={() => {
                          addNote(note);
                          setActiveTab("");
                        }}
                      >
                        {t("Save")}
                      </div>
                      <div
                        onClick={() => setActiveTab("")}
                        className="text-button-uppercase mt-4 text-center has-line-before cursor-pointer inline-block"
                      >
                        {t("Cancel")}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-item note-block ${
                      activeTab === "shipping" ? "active" : ""
                    }`}
                  >
                    <div className="px-6 py-4 border-b border-line">
                      <div className="item flex items-center gap-3 cursor-pointer">
                        <Icon.Truck className="text-xl" />
                        <div className="caption1">
                          {t("Estimate shipping rates")}
                        </div>
                      </div>
                    </div>
                    <div className="form pt-4 px-6">
                      <div className="">
                        <label
                          htmlFor="select-country"
                          className="caption1 text-secondary"
                        >
                          {t("Country")}
                        </label>
                        <div className="select-block relative mt-2">
                          <select
                            id="select-country"
                            name="select-country"
                            className="w-full py-3 pl-5 rounded-xl bg-white border border-line"
                            onChange={(ev) => {
                              setSelectedArea(
                                cities.find(
                                  (x) => x.country === ev.target.value
                                )
                              );
                            }}
                          >
                            {cities.map((x) => (
                              <option value={x.country}>{x.country}</option>
                            ))}
                            {/* <option value='KW' disabled>
                              Kuwait
                            </option> */}
                          </select>
                          <Icon.CaretDown
                            size={12}
                            className="absolute top-1/2 -translate-y-1/2 md:right-5 right-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="block-button text-center pt-4 px-6 pb-6">
                      <div className="product-price text-title">
                        {`${t("Shipping Fees")}: ${formatPrice(
                          calculateTotalDeliveryRateByCountry(
                            cartWeight,
                            selectedArea
                          ),
                          "KD"
                        )}`}
                      </div>
                      <div
                        onClick={() => setActiveTab("")}
                        className="text-button-uppercase mt-4 text-center has-line-before cursor-pointer inline-block"
                      >
                        {t("Close")}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-item note-block ${
                      activeTab === "coupon" ? "active" : ""
                    }`}
                  >
                    <div className="px-6 py-4 border-b border-line">
                      <div className="item flex items-center gap-3 cursor-pointer">
                        <Icon.Tag className="text-xl" />
                        <div className="caption1">{t("Add A Coupon Code")}</div>
                      </div>
                    </div>
                    <div className="form pt-4 px-6">
                      <div className="">
                        <label
                          htmlFor="select-discount"
                          className="caption1 text-secondary"
                        >
                          {t("Enter Code")}
                        </label>
                        <input
                          className="border-line px-5 py-3 w-full rounded-xl mt-3"
                          id="select-discount"
                          type="text"
                          placeholder="Discount code"
                          value={couponCode}
                          onChange={(ev) => setCouponCode(ev.target.value)}
                        />
                      </div>
                    </div>
                    <div className="block-button text-center pt-4 px-6 pb-6">
                      <div
                        className="button-main w-full text-center"
                        onClick={() => handleValidateCoupon()}
                      >
                        {t("Apply")}
                      </div>
                      <div
                        onClick={() => setActiveTab("")}
                        className="text-button-uppercase mt-4 text-center has-line-before cursor-pointer inline-block"
                      >
                        {t("Cancel")}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {shoppingCart?.length >= 1 ? (
              <div
                className="remove-cart-btn caption1 font-semibold text-red underline cursor-pointer"
                style={{ textAlign: "center" }}
                onClick={() => {
                  clearCart();
                }}
              >
                {t("Remove All")}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCart;
