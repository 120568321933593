//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import axios, { endpoints } from "@/utils/axios";
import { format } from "date-fns";
import { usePrices } from "@/context/PricesContext";
import { useLanguages } from "@/context/LanguageContext";
import { useCart } from "@/context/CartContext";

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

const OrderTracking = () => {
  const [searchParams] = useSearchParams();
  const trackingId = searchParams.get("trackingId");
  const successReturn = searchParams.get("success");
  const { langState, t } = useLanguages();
  const { formatPrice } = usePrices();
  const [trackingNumber, setTrackingNumber] = useState(trackingId);
  const [order, setOrder] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { clearCart } = useCart();
  useEffect(() => {
    if (trackingId) {
      axios
        .get(`${endpoints.shoppingCart.track}?trackId=${trackingId}`)
        .then((resp) => {
          if (resp.status == 200) {
            setOrder(resp.data);
          } else {
            setErrorMessage(t("An error occured! Please try again."));
          }
        });
    }
  }, [trackingId]);
  useEffect(() => {
    if (successReturn) {
      clearCart();
    }
  }, [successReturn]);
  const handleTrackingOrder = async () => {
    setErrorMessage(null);
    if (!trackingNumber) {
      setErrorMessage(t("Please fill the order tracking number!"));
      return;
    }
    let resp = await axios.get(
      `${endpoints.shoppingCart.track}?trackId=${trackingNumber}`
    );

    if (resp.status == 200) {
      setOrder(resp.data);
    } else {
      setErrorMessage(t("An error occured! Please try again."));
    }
  };

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="Order Tracking" subHeading="Order Tracking" />
      </div>
      <div className="order-tracking md:py-20 py-10">
        <div className="container">
          <div className="content-main flex gap-y-8 max-md:flex-col">
            <div className="left md:w-1/2 w-full lg:pr-[60px] md:pr-[40px] md:border-r border-line">
              <div className="heading4">{t("Order Tracking")}</div>
              <div className="mt-2">
                {t(
                  "To track your order please enter your Order ID in the box below and press the Track button. This was given to you on your receipt and in the confirmation email you should have received."
                )}
              </div>
              <div className="md:mt-7 mt-4">
                <div className="billing mt-5">
                  <input
                    className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                    id="trackingNumber"
                    placeholder="Tracking Number *"
                    required
                    value={trackingNumber}
                    onChange={(ev) => setTrackingNumber(ev.target.value)}
                  />
                </div>
                {errorMessage && (
                  <label className="cursor-pointer text-red">
                    {errorMessage}
                  </label>
                )}
                <div className="block-button md:mt-7 mt-4">
                  <button
                    className="button-main"
                    onClick={() => handleTrackingOrder()}
                  >
                    {t("Tracking Order")}
                  </button>
                </div>
              </div>
            </div>
            <div className="right md:w-1/2 w-full lg:pl-[60px] md:pl-[40px] flex items-center">
              <div className="text-content">
                {order && (
                  <>
                    <div className="heading5">{t("ThankYou")}</div>
                    <div className="heading4">Order: {order?.trackingId}</div>
                    {order?.status && (
                      <div className="mt-2 text-secondary">
                        {t("Order Status")}: {order?.status}
                      </div>
                    )}
                    {order?.createdAt && (
                      <div className="mt-2 text-secondary">
                        {t("Order Created")}: {fDateTime(order?.createdAt)}
                      </div>
                    )}
                    {order?.paidAt && (
                      <div className="mt-2 text-secondary">
                        {t("Order Paid")}: {fDateTime(order?.paidAt)}
                      </div>
                    )}
                    {order?.shippedAt && (
                      <div className="mt-2 text-secondary">
                        {t("Order Shipped")}: {fDateTime(order?.shippedAt)}
                      </div>
                    )}
                    {order?.deliveredAt && (
                      <div className="mt-2 text-secondary">
                        {t("Order Delivered")}: {fDateTime(order?.deliveredAt)}
                      </div>
                    )}
                    {order?.canceledAt && (
                      <div className="mt-2 text-secondary">
                        {t("Order Canceled")}: {fDateTime(order?.canceledAt)}
                      </div>
                    )}
                    <div className="block-button md:mt-7 mt-4"></div>
                  </>
                )}

                {order?.items?.map((product) => {
                  const productPrice =
                    (product.salePrice || product?.price) +
                    ((product.color?.additionalPrice || 0) +
                      (product.size?.additionalPrice || 0) +
                      (product?.variations || [])?.reduce(
                        (acc, curr) =>
                          acc + curr?.value ? curr.additionalPrice : 0,
                        0
                      ));
                  return (
                    <div key={product.id}>
                      <div className="item flex items-center justify-between w-full pb-5 border-b border-line gap-6 mt-5">
                        <div className="bg-img w-[100px] aspect-square flex-shrink-0 rounded-lg overflow-hidden">
                          <img
                            src={product.variationImageURL ?? product.imageURL}
                            width={300}
                            height={300}
                            alt="img"
                            className="w-full h-full"
                          />
                        </div>
                        <div className="flex items-center justify-between w-full">
                          <div>
                            <div className="name text-title">
                              {`${product.name?.[langState?.lang]}`}
                            </div>
                            <div className="caption1 text-secondary mt-2">
                              <span className="size capitalize">
                                {`${
                                  product.size?.name?.[langState?.lang] ?? ""
                                }${product.size && product.color ? " / " : ""}${
                                  product.color?.name?.[langState?.lang] ?? ""
                                }`}
                              </span>

                              <span className="color capitalize">
                                {product?.variations?.map((vari) => (
                                  <div className="flex items-center text-secondary2 capitalize">
                                    {`${vari?.textValue || vari?.value}`}
                                  </div>
                                ))}
                              </span>
                            </div>
                          </div>
                          <div className="text-title">
                            <span className="quantity">
                              {product.quantityPurchase}
                            </span>
                            <span className="px-1">x</span>
                            <span>
                              {formatPrice(
                                productPrice,
                                product?.currency || "KD"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderTracking;
