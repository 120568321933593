import { useQuery } from "@tanstack/react-query";
import { useMemo, useEffect, useRef } from "react";
import useLocalStorage, { LocalStorageKeys } from "@/hooks/localStorage";
import { fetcher, endpoints } from "@/utils/axios";

// ----------------------------------------------------------------------

export function useGetMenu() {
  const URL = endpoints.menu.all;
  const { saveToLocalStorage } = useLocalStorage();
  const random = useRef(Date.now());
  const { data, isLoading, error, isValidating, refetch } = useQuery({
    queryKey: [URL, random],
    queryFn: () => fetcher(URL),
  });

  useEffect(() => {
    if (data) {
      saveToLocalStorage(LocalStorageKeys.MENU, data);
    }
  }, [data]);

  const memoizedValue = useMemo(
    () => ({
      menu: data || [],
      menuLoading: isLoading,
      menuError: error,
      menuValidating: isValidating,
      menuEmpty: !isLoading && !data?.data?.length,
      menuRefetch: refetch,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
