import React, { useState, useEffect, useMemo, Suspense } from "react";
// import { Instrument_Sans } from "next/font/google";
import "@/styles/styles.scss";
import GlobalProvider from "./GlobalProvider";
import ModalCart from "@/components/Modal/ModalCart";
import ModalWishlist from "@/components/Modal/ModalWishlist";
import SplashScreen from "@/components/SplashScreen/SplashScreen";
import { useLocation, BrowserRouter } from "react-router-dom";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { type Container, type ISourceOptions } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";
import { ErrorBoundary } from "react-error-boundary";
import axios, { endpoints } from "@/utils/axios";
import { browserName } from "react-device-detect";

import AppRoutes from "./routes";

function App() {
  // this should be run only once per application lifetime
  useEffect(() => {
    try {
      initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      });
    } catch (err) {
      axios.post(endpoints.log.add, {
        message: "WEBSITE ERROR",
        stack: "layout.tsx",
        data: err,
        additionalInfo: browserName,
      });
    }
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {};

  const options: ISourceOptions = useMemo(
    () => ({
      key: "snow",
      name: "Snow",
      particles: {
        number: {
          value: 400,
          density: {
            enable: true,
          },
        },
        color: {
          value: "#e6dace",
        },
        shape: {
          type: "circle",
        },
        opacity: {
          value: 0.5,
        },
        size: {
          value: 10,
        },
        move: {
          enable: true,
          speed: 2,
          direction: "bottom-right",
          straight: false,
        },
        wobble: {
          enable: true,
          distance: 10,
          speed: 10,
        },
        zIndex: {
          value: {
            min: 0,
            max: 100,
          },
          opacityRate: 10,
          sizeRate: 10,
          velocityRate: 10,
        },
      },
      background: {
        color: "#fff",
      },
    }),
    []
  );

  const logError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error, e.g. log to an external API
    axios.post(endpoints.log.add, {
      message: "WEBSITE ERROR",
      stack: info?.componentStack,
      data: error,
      additionalInfo: browserName,
    });
  };
  return (
    <div className="App">
      <ErrorBoundary
        FallbackComponent={() => (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        )}
        //@ts-ignore
        onError={logError}
      >
        <Suspense
          fallback={
            <Particles
              id="tsparticles"
              particlesLoaded={particlesLoaded}
              options={options}
            />
          }
        >
          <GlobalProvider>
            <BrowserRouter>
              <AppRoutes />
              <ModalCart />
              <ModalWishlist />
            </BrowserRouter>
          </GlobalProvider>
        </Suspense>
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      </ErrorBoundary>
    </div>
  );
}

export default App;
