// UserContext.tsx
import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";
import axios, { endpoints, setSession } from "@/utils/axios"; // Adjust the import path as necessary
import useLocalStorage, { LocalStorageKeys } from "@/hooks/localStorage"; // Adjust the import path as necessary

interface UserState {
  user: any | null;
}

type UserAction =
  | { type: "SET_USER"; payload: any | null }
  | { type: "CLEAR_USER" };

interface UserContextProps {
  userState: UserState;
  validateUser: ({
    token,
    email,
  }: {
    token: string;
    email: string;
  }) => Promise<any>;
  saveUserToLocalStorage: (userInfo: any) => void;
  clearUserFromLocalStorage: () => void;
  getUserFromLocalStorage: () => any;
  validateUserPeriodically: () => any;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "CLEAR_USER":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userState, dispatch] = useReducer(userReducer, { user: null });
  const {
    saveToLocalStorage,
    clearDataFromLocalStorage,
    readFromLocalStorage,
  } = useLocalStorage();

  useEffect(() => {
    const userInfo = readFromLocalStorage(LocalStorageKeys.USER);
    if (userInfo) {
      dispatch({ type: "SET_USER", payload: userInfo });
      //@ts-ignore
      setSession(userInfo.token); // Ensure to handle session management as needed
    }
  }, []);

  useEffect(() => {
    const visit = readFromLocalStorage(LocalStorageKeys.VISIST);
    if (!visit) {
      saveToLocalStorage(LocalStorageKeys.VISIST, "YTJ809dsYsa4");
      axios.post(endpoints.visit.add, { value: "YTJ809dsYsa4" });
    } else {
    }
  }, []);

  const saveUserToLocalStorage = (userInfo: any) => {
    if (userInfo.email && userInfo.token) {
      saveToLocalStorage(LocalStorageKeys.USER, userInfo);
      dispatch({ type: "SET_USER", payload: userInfo });
      setSession(userInfo.token); // Ensure to handle session management as needed
    } else {
      clearUserFromLocalStorage();
    }
  };

  const clearUserFromLocalStorage = () => {
    clearDataFromLocalStorage(LocalStorageKeys.USER);
    dispatch({ type: "CLEAR_USER" });
    setSession(null); // Ensure to handle session management as needed
  };

  const getUserFromLocalStorage = () => {
    return readFromLocalStorage(LocalStorageKeys.USER);
  };

  const validateUser = async ({
    token,
    email,
  }: {
    token: string;
    email: string;
  }) => {
    if (!email || !token) {
      clearUserFromLocalStorage();
      return;
    }
    let resp = await axios.post(endpoints.auth.me, { token }); // Adjust endpoint as necessary

    if (resp?.status !== 200) {
      clearUserFromLocalStorage();
      return false;
    }

    let userInfo = { ...resp?.data?.user, token: resp?.data?.token };
    saveUserToLocalStorage(userInfo);
    return userInfo;
  };

  // Function to periodically validate user
  const validateUserPeriodically = () => {
    if (userState.user && userState.user?.token && userState.user?.email) {
      validateUser({
        token: userState.user?.token,
        email: userState.user?.email,
      });
    }
  };

  useEffect(() => {
    // Set interval for 10 minutes
    const interval = setInterval(validateUserPeriodically, 600000); // 600000 ms = 10 minutes

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, [userState.user]); // Dependency array includes `user` to ensure the timer is reset if the user changes

  return (
    <UserContext.Provider
      value={{
        userState,
        validateUser,
        saveUserToLocalStorage,
        clearUserFromLocalStorage,
        getUserFromLocalStorage,
        validateUserPeriodically,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
