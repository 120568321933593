import React, { useEffect, useState } from "react";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import MainSlider from "@/components/Slider/SliderSloth";
import Collection from "@/components/Sloth/Collection";
import BannerTop from "@/components/Home3/BannerTops";
import TabFeatures from "@/components/Sloth/TabFeatures";
import Footer from "@/components/Footer/Footer";
import { LocalStorageKeys, readFromLocalStorage } from "@/hooks/localStorage";
import { useLanguages } from "@/context/LanguageContext";
import axios, { endpoints } from "@/utils/axios";

import { useGetShop } from "@/api/shop";
import { useGetMenu } from "@/api/menu";
import { useCart } from "@/context/CartContext";
import { useGetFeatured } from "@/api/featured";

export default function Home() {
  const { langState } = useLanguages();
  const [shop, setShop] = useState({});

  const { shopRefetch, shop: shopItem } = useGetShop();
  const { menuRefetch, menu } = useGetMenu();
  const { setMenu, setShopT } = useCart();
  const { featuredRefetch } = useGetFeatured();

  useEffect(() => {
    try {
      menuRefetch();
      // const menu_ = readFromLocalStorage(LocalStorageKeys?.MENU);
      // if (menu_) {
      // } else {
      //   menuRefetch();
      // }
    } catch (err) {
      axios.post(endpoints.log.add, {
        message: "WEBSITE ERROR",
        stack: "home.tsx",
        data: err,
        additionalInfo: "menu",
      });
    }
  }, []);
  useEffect(() => {
    //@ts-ignore
    const allProducts = menu?.reduce((allProducts, category) => {
      return allProducts.concat(category.products);
    }, []);
    setMenu(allProducts ?? []);
  }, [menu]);

  useEffect(() => {
    try {
      const shop_ = readFromLocalStorage(LocalStorageKeys?.SHOP);
      if (shop_) {
        //@ts-ignore
        setShop(shop_);
      }
      shopRefetch();
    } catch (err) {
      axios.post(endpoints.log.add, {
        message: "WEBSITE ERROR",
        stack: "home.tsx",
        data: err,
        additionalInfo: "shop",
      });
    }
  }, []);

  useEffect(() => {
    if (shopItem) {
      setShop(shopItem);
      setShopT(shopItem);
    }
  }, [shopItem]);

  const [featured, setFeatured] = useState({
    newArrivals: [],
    sales: [],
    bestSellers: [],
  });

  useEffect(() => {
    try {
      const featured_ = readFromLocalStorage(LocalStorageKeys?.FEATURED);
      if (featured_) {
        //@ts-ignore
        setFeatured(featured_);
      } else {
        featuredRefetch();
      }
    } catch (err) {
      axios.post(endpoints.log.add, {
        message: "WEBSITE ERROR",
        stack: "home.tsx",
        data: err,
        additionalInfo: "featured",
      });
    }
  }, []);

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        {/*@ts-ignore*/}
        {shop?.headerMessage?.[langState?.lang] ? (
          <BannerTop
            /*@ts-ignore*/
            message={shop?.headerMessage?.[langState?.lang]}
            props={`py-4`}
            textColor="#977052"
            bgLine="bg-[#977052]"
          />
        ) : (
          <></>
        )}
        {/*@ts-ignore*/}
        <MainSlider announcements={shop?.announcements || []} />
      </div>

      <Collection props="pt-5" />
      <TabFeatures data={featured} />
      <Footer />
    </>
  );
}
