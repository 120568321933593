import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { fetcher, endpoints } from "@/utils/axios";
// ----------------------------------------------------------------------

export function useKWCities() {
  const URL = `${endpoints.delivery.cities}?country=kuwait`;

  const { data, isLoading, error, isValidating } = useQuery({
    queryKey: [URL],
    queryFn: () => fetcher(URL),
  });

  const memoizedValue = useMemo(
    () => ({
      kwCities: data || [],
      citiesLoading: isLoading,
      citiesError: error,
      citiesValidating: isValidating,
      citiesEmpty: !isLoading && !data?.data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
