//@ts-nocheck
import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
  useState,
  useCallback,
} from "react";
import axios, { endpoints } from "@/utils/axios"; // Adjust import path as necessary
// import { useGetShoppingCart } from "@/api/cart";
import {
  LocalStorageKeys,
  saveToLocalStorage,
  readFromLocalStorage,
} from "@/hooks/localStorage";

interface CartState {
  cartItems: any[];
  cartTotal: 0;
  cartSaleTotal: 0;
  cartWeight: 0;
  currency: "KD";
  notes: "";
  coupon: null;
}

type CartAction = { type: string; payload: any };

interface CartContextProps extends CartState {
  shoppingCart: any;
  cartTotal: any;
  cartSaleTotal: any;
  cartWeight: any;
  currency: string;
  notes: string;
  coupon: any;
  notes: any;
  cartLoading: Boolean;
  discount: any;
  updateCart: (cartItems: any[]) => void;
  clearCart: () => void;
  addToShoppingCart: (item: any) => Promise<void>;
  removeFromShoppingCart: (cartProductId: string) => Promise<void>;
  increaseQuantity: (cartProductId: object) => Promise<void>;
  decreaseQuantity: (cartProductId: object) => Promise<void>;
  productInCart: (item: any) => any;
  isSimilarAvailableInCart: (item: any) => Boolean;
  isAvailableInCart: (item: any) => any;
  validateCoupon: (item: any) => any;
  addNote: (note: any) => any;
  submitOrder: (details: any) => any;
  setMenu: (details: any) => any;
  setShopT: (details: any) => any;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case "SET_CART":
      try {
        saveToLocalStorage(
          LocalStorageKeys.CART,
          JSON.stringify({
            ...state,
            ...action.payload,
          })
        );
      } catch (err) {
        console.log("ERROR UPDATING CART", err);
      }
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_CART":
      try {
        saveToLocalStorage(
          LocalStorageKeys.CART,
          JSON.stringify({
            cartItems: [],
            cartTotal: 0,
            cartSaleTotal: 0,
            cartWeight: 0,
            currency: "KD",
            notes: "",
            coupon: null,
          })
        );
      } catch {}
      return {
        cartItems: [],
        cartTotal: 0,
        cartSaleTotal: 0,
        cartWeight: 0,
        currency: "KD",
        notes: "",
        coupon: null,
      };
    default:
      return state;
  }
};

const DefaultCart = {
  cartItems: [],
  cartWeight: 0,
  cartTotal: 0,
  cartSaleTotal: 0,
  currency: "KD",
  notes: "",
  coupon: null,
};
export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cartState, dispatch] = useReducer(cartReducer, DefaultCart);

  const [menu, setMenu] = useState([]);
  const [shop, setShop] = useState();

  useEffect(() => {
    const menu_ = readFromLocalStorage(LocalStorageKeys.MENU);
    const allProducts = menu_?.reduce((allProducts, category) => {
      return allProducts.concat(category.products);
    }, []);
    setMenu(allProducts ?? []);
  }, []);

  useEffect(() => {
    const cart_ = readFromLocalStorage(LocalStorageKeys.CART);

    updateCart(cart_);
  }, [shop]);

  useEffect(() => {
    const shop_ = readFromLocalStorage(LocalStorageKeys.SHOP);
    setShop(shop_);
  }, []);

  // useEffect(() => {
  //   if (!cartLoading && !cartValidating) {
  //     updateCart(cart || DefaultCart);
  //   }
  // }, [cart, cartValidating, cartLoading]);

  const shoppingCart = cartState.cartItems || [];

  const cartWeight = cartState.cartWeight || 0;
  const cartTotal = cartState.cartTotal || 0;
  const cartSaleTotal = cartState.cartSaleTotal || 0;
  const currency = cartState.currency || "KD";
  const coupon = cartState.coupon || null;

  const calculateCartValues = (cartItems) => {
    console.log("CART ITEMS", cartItems);
    if (!shop?.currencies) {
      console.log("NO currencies");
      return false;
    }

    try {
      const { currencies } = shop;
      console.log("currencies", currencies);
      if (!currencies) {
        return false;
        // throw new Error("Internel Error C");
      }

      const cartTotal = cartItems?.reduce(
        (acc, curr) =>
          acc +
          curr.quantityPurchase *
            (curr.price +
              (curr.size?.additionalPrice || 0) +
              (curr.color?.additionalPrice || 0) +
              (curr.variations || [])
                .filter((x) => !!x.value)
                ?.reduce(
                  (varAcc, currAcc) => varAcc + currAcc.additionalPrice ?? 0,
                  0
                )) *
            (currencies?.find((x) => x.name?.en === curr.currency)?.value || 1),
        0
      );
      const cartSaleTotal = cartItems?.reduce(
        (acc, curr) =>
          acc +
          curr.quantityPurchase *
            (curr.salePrice > 0 ? curr.salePrice - curr.price : 0) *
            (currencies?.find((x) => x.name?.en === curr.currency)?.value || 1),
        0
      );

      const cartWeight = cartItems?.reduce(
        (acc, curr) => acc + curr.quantityPurchase * (curr.weight || 0),
        0
      );
      return {
        cartItems,
        cartTotal,
        cartSaleTotal,
        cartWeight,
        currency: "KD",
      };
    } catch (error) {
      return false;
    }
  };
  const updateCart = async (updates: any) => {
    console.log("UPDATING CART", updates);
    let toBeUpdated = updates;
    if (toBeUpdated?.cartItems) {
      toBeUpdated = calculateCartValues(updates?.cartItems);
    }
    console.log("CART TO BE UPDATED", toBeUpdated);
    if (!toBeUpdated) {
      return;
    }
    dispatch({
      type: "SET_CART",
      payload: toBeUpdated,
    });
  };

  const clearCart = async () => {
    dispatch({
      type: "CLEAR_CART",
    });
  };

  const generateProductId = (
    productId,
    color = undefined,
    size = undefined,
    variations = []
  ) =>
    `${productId}-${color?.id ?? ""}-${size?.id ?? ""}-${variations?.map((x) =>
      [x.id, x.value, x.textValue].join("-")
    )}`;

  const productInCart = (
    productId,
    color = null,
    size = null,
    variations = []
  ) => {
    const cartProductId = generateProductId(productId, color, size, variations);
    return (shoppingCart || [])?.find((x) => x.id === cartProductId);
  };

  const isAvailableInCart = (
    productId,
    color = null,
    size = null,
    variations = []
  ) => {
    return !!productInCart(productId, color, size, variations);
  };

  const isSimilarAvailableInCart = (
    productId,
    color = null,
    size = null,
    variations = []
  ) => {
    return (
      !isAvailableInCart(productId, color, size, variations) &&
      !!shoppingCart?.find((x) => x.id.split("-")?.[0] === productId)
    );
  };

  const addToShoppingCart = async ({
    productId,
    color,
    size,
    variations,
    quantityPurchase,
    isPreOrder,
    availabilityDate,
  }: any) => {
    console.log("ADDING", {
      productId,
      color,
      size,
      variations,
      quantityPurchase,
      isPreOrder,
      availabilityDate,
    });
    console.log("shoppingCart", shoppingCart);
    let cartItems = shoppingCart;
    const cartProductId = generateProductId(productId, color, size, variations);
    const isProductAvailableInCart = isAvailableInCart(
      productId,
      color,
      size,
      variations
    );
    if (isProductAvailableInCart) {
      cartItems.find((x) => x.id === cartProductId).quantityPurchase +=
        quantityPurchase;
    } else {
      const product = menu?.find((x) => x._id === productId);
      console.log("PRODUCT", product);
      if (!!product) {
        cartItems.push({
          id: cartProductId,
          productId,
          name: product?.name,
          category: product?.category,
          currency: product?.currency || "KD",
          price: product?.price,
          salePrice: product?.salePrice,
          imageURL: product?.imageURL,
          weight: product?.weight ?? 0,
          color,
          size,
          variations,
          variationImageURL: color?.imageURL || size?.imageURL || null,
          quantityPurchase,
          isPreOrder,
          availabilityDate,
        });
      }
    }
    updateCart({ cartItems });
  };

  const removeFromShoppingCart = async ({
    cartProductId: cartProductId_,
    productId,
    color,
    size,
    variations,
  }) => {
    const cartProductId =
      cartProductId_ ?? generateProductId(productId, color, size, variations);
    let cartItems = shoppingCart?.filter((x) => x.id !== cartProductId) || [];
    updateCart({ cartItems });
  };

  const increaseQuantity = async ({
    cartProductId: cartProductId_,
    productId,
    color,
    size,
    variations,
    quantityPurchase,
    isPreOrder,
    availabilityDate,
  }) => {
    let cartItems = shoppingCart;
    const cartProductId =
      cartProductId_ ?? generateProductId(productId, color, size, variations);
    const isProductAvailableInCart = isAvailableInCart(
      productId,
      color,
      size,
      variations
    );
    if (isProductAvailableInCart) {
      cartItems.find((x) => x.id === cartProductId).quantityPurchase +=
        quantityPurchase;
    } else {
      const product = menu?.find((x) => x._id === productId);
      if (!!product) {
        cartItems.push({
          id: cartProductId,
          productId,
          name: product?.name,
          category: product?.category,
          currency: product?.currency || "KD",
          price: product?.price,
          salePrice: product?.salePrice,
          imageURL: product?.imageURL,
          weight: product?.weight ?? 0,
          color,
          size,
          variations,
          variationImageURL: color?.imageURL || size?.imageURL || null,
          quantityPurchase,
          isPreOrder,
          availabilityDate,
        });
      }
    }
    updateCart({ cartItems });
  };

  const decreaseQuantity = async ({
    cartProductId: cartProductId_,
    productId,
    color,
    size,
    variations,
    quantityPurchase,
  }) => {
    let cartItems = shoppingCart;
    const cartProductId =
      cartProductId_ ?? generateProductId(productId, color, size, variations);
    const isProductAvailableInCart = isAvailableInCart(
      productId,
      color,
      size,
      variations
    );

    if (isProductAvailableInCart) {
      if (
        cartItems.find((x) => x.id === cartProductId).quantityPurchase >
        quantityPurchase
      ) {
        cartItems.find((x) => x.id === cartProductId).quantityPurchase -=
          quantityPurchase;
      } else {
        cartItems = cartItems.filter((x) => x.id !== cartProductId);
      }
    }
    updateCart({ cartItems });
  };

  const validateCoupon = async (code) => {
    if (!code) {
      return null;
    }
    let resp = await axios.post(endpoints.shoppingCart.coupon, {
      couponCode: code,
    });
    if (resp.status === 200) {
      updateCart({ coupon: resp.data });
    }
    return null;
  };

  const addNote = async (note) => {
    updateCart({ notes: note });
  };
  const discount =
    (!!coupon
      ? coupon.type === "FIX"
        ? coupon.value
        : ((cartTotal + cartSaleTotal) * coupon.value) / 100
      : 0) *
      -1 +
    cartSaleTotal;

  const notes = cartState.notes;

  const submitOrder = async ({
    address,
    userInfo,
    deliveryFees,
    isPreOrder,
    availabilityDate,
    paymentMethod,
    lang = "en",
  }) => {
    let details = {
      address: {
        ...address,
        deliveryFees,
        ...userInfo,
      },
      userInfo,
      items: shoppingCart,
      coupon,
      deliveryFees_fe: deliveryFees,
      isPreOrder,
      availabilityDate,
      paymentMethod,
      lang,
    };

    let resp = await axios.post(endpoints.shoppingCart.submit, details);

    if (resp.status == 200 && resp?.data) {
      window.location.href = resp?.data;
      return true;
    } else {
      return false;
    }
  };

  return (
    <CartContext.Provider
      value={{
        ...cartState,
        shoppingCart,
        cartTotal,
        cartWeight,
        cartSaleTotal,
        currency,
        discount,
        coupon,
        notes,
        validateCoupon,
        isSimilarAvailableInCart,
        isAvailableInCart,
        productInCart,
        updateCart,
        addToShoppingCart,
        removeFromShoppingCart,
        increaseQuantity,
        decreaseQuantity,
        addNote,
        submitOrder,
        clearCart,
        setMenu,
        setShopT: setShop,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
