import { useEffect } from "react";
import { useLanguages } from "@/context/LanguageContext";

interface Props {
  amount: number;
  discount: number | null;
  delivery: number | null;
}

const TabbyCheckout: React.FC<Props> = ({ amount, discount, delivery }) => {
  const { langState } = useLanguages();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.tabby.ai/tabby-card.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      //@ts-ignore
      new TabbyCard({
        selector: "#tabbyCard",
        currency: "KWD",
        //@ts-ignore
        price: (amount + discount ?? 0 + delivery ?? 0)?.toFixed(2),

        lang: langState.lang,
        size: "wide", // required, can be also 'wide', depending on the width.
        theme: "default", // required, can be also 'default'.
        header: false,
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [amount, discount, delivery, langState.lang]);

  return <div id="tabbyCard" style={{ marginTop: 20 }}></div>;
};
export default TabbyCheckout;
