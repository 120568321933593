//@ts-nocheck
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import * as Icon from "@phosphor-icons/react/dist/ssr";
import { useCart } from "@/context/CartContext";
import { usePrices } from "@/context/PricesContext";
import { useLanguages } from "@/context/LanguageContext";
import TabbyComponent from "@/components/Tabby/TabbyPromo";

const Cart = () => {
  const { langState, t } = useLanguages();
  const navigate = useNavigate();
  const {
    shoppingCart,
    cartTotal,
    currency,
    notes,
    addNote,
    coupon,
    discount,
    validateCoupon,
    decreaseQuantity,
    increaseQuantity,
  } = useCart();
  const { formatPrice } = usePrices();
  // const discount =
  //   (!!coupon
  //     ? coupon.type === "FIX"
  //       ? -1 * coupon.value
  //       : ((cartTotal + cartSaleTotal) * coupon.value) / 100
  //     : 0) + cartSaleTotal;
  const [couponCode, setCouponCode] = useState("");

  const redirectToCheckout = () => {
    navigate(`/checkout`);
  };
  const handleValidateCoupon = () => {
    validateCoupon(couponCode);
  };
  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="Shopping cart" subHeading="Shopping cart" />
      </div>
      <div className="cart-block md:py-20 py-10">
        <div className="container">
          <div className="content-main flex justify-between max-xl:flex-col gap-y-8">
            <div className="xl:w-2/3 xl:pr-3 w-full">
              <div className="list-product w-full sm:mt-7 mt-5">
                <div className="w-full">
                  <div className="heading bg-surface bora-4 pt-4 pb-4">
                    <div className="flex">
                      <div className="w-1/2">
                        <div className="text-button text-center">
                          {t("Products")}
                        </div>
                      </div>
                      <div className="w-1/12">
                        <div className="text-button text-center">
                          {t("Price")}
                        </div>
                      </div>
                      <div className="w-1/6">
                        <div className="text-button text-center">
                          {t("Quantity")}
                        </div>
                      </div>
                      <div className="w-1/6">
                        <div className="text-button text-center">
                          {t("Total Price")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-product-main w-full mt-3">
                    {shoppingCart?.length < 1 ? (
                      <p className="text-button pt-3">
                        {t("No product in cart")}
                      </p>
                    ) : (
                      shoppingCart?.map((product) => {
                        const productPrice =
                          (product.salePrice || product?.price) +
                          ((product.color?.additionalPrice || 0) +
                            (product.size?.additionalPrice || 0) +
                            (product?.variations || [])?.reduce(
                              (acc, curr) =>
                                acc + curr?.value ? curr.additionalPrice : 0,
                              0
                            ));

                        return (
                          <div
                            className="item flex md:mt-7 md:pb-7 mt-5 pb-5 border-b border-line w-full"
                            key={product.id}
                          >
                            <div className="w-1/2">
                              <div className="flex items-center gap-6">
                                <div className="bg-img md:w-[100px] w-20 aspect-[1/1]">
                                  {product.variationImageURL ||
                                  product.imageURL ? (
                                    <img
                                      src={
                                        product.variationImageURL ??
                                        product.imageURL
                                      }
                                      width={300}
                                      height={300}
                                      alt={product.name?.[langState?.lang]}
                                      className="w-full h-full"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div>
                                  <div className="text-title">
                                    {`${product.quantityPurchase} x ${
                                      product.name?.[langState?.lang]
                                    }`}
                                  </div>

                                  <div className="flex items-center justify-between gap-2  w-full">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div className="flex items-center text-secondary2 capitalize">
                                        {`${
                                          product?.isPreOrder
                                            ? `${t("Around")}: ${
                                                product?.availabilityDate?.split(
                                                  "T"
                                                )?.[0]
                                              }`
                                            : ""
                                        }`}
                                      </div>

                                      <div className="flex items-center text-secondary2 capitalize">
                                        {`${
                                          product.size?.name?.[
                                            langState?.lang
                                          ] ?? ""
                                        }${
                                          product.size && product.color
                                            ? " / "
                                            : ""
                                        }${
                                          product.color?.name?.[
                                            langState?.lang
                                          ] ?? ""
                                        }`}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center justify-between  w-full">
                                    {product?.variations?.map((vari) => (
                                      <div className="flex items-center text-secondary2 capitalize">
                                        {`${vari?.textValue || vari?.value}`}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/12 price flex items-center justify-center">
                              <div className="text-title text-center">
                                {formatPrice(
                                  productPrice,
                                  product?.currency || "KD"
                                )}
                              </div>
                            </div>
                            <div className="w-1/6 flex items-center justify-center">
                              <div className="quantity-block bg-surface md:p-3 p-2 flex items-center justify-between rounded-lg border border-line md:w-[100px] flex-shrink-0 w-20">
                                <Icon.Minus
                                  onClick={() => {
                                    // @ts-ignore
                                    decreaseQuantity({
                                      cartProductId: product?.cartProductId,
                                      productId: product?.productId,
                                      color: product?.color || null,
                                      size: product?.size || null,
                                      variations: product?.variations || [],
                                      quantityPurchase: 1,
                                    });
                                  }}
                                  className={`text-base max-md:text-sm ${
                                    product.quantityPurchase === 1
                                      ? "disabled"
                                      : ""
                                  }`}
                                />
                                <div className="text-button quantity">
                                  {product.quantityPurchase}
                                </div>
                                <Icon.Plus
                                  onClick={() => {
                                    // @ts-ignore
                                    increaseQuantity({
                                      cartProductId: product?.cartProductId,
                                      productId: product?.productId,
                                      color: product?.color || null,
                                      size: product?.size || null,
                                      variations: product?.variations || [],
                                      quantityPurchase: 1,
                                      isPreOrder: product?.isPreOrder,
                                      availabilityDate:
                                        product?.availabilityDate,
                                    });
                                  }}
                                  className="text-base max-md:text-sm"
                                />
                              </div>
                            </div>
                            <div className="w-1/6 flex total-price items-center justify-center">
                              <div className="text-title text-center">
                                {formatPrice(
                                  productPrice * product.quantityPurchase,
                                  product?.currency || "KD"
                                )}
                              </div>
                            </div>
                            <div className="w-1/12 flex items-center justify-center">
                              <Icon.XCircle
                                className="text-xl max-md:text-base text-red cursor-pointer hover:text-black duration-500"
                                onClick={() => {
                                  // @ts-ignore
                                  decreaseQuantity({
                                    cartProductId: product?.cartProductId,
                                    productId: product?.productId,
                                    color: product?.color || null,
                                    size: product?.size || null,
                                    variations: product?.variations || [],
                                    quantityPurchase: product?.quantityPurchase,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="input-block discount-code w-full h-12 sm:mt-7 mt-5">
                <form className="w-full h-full relative">
                  <input
                    type="text"
                    placeholder={t("Add voucher discount")}
                    className="w-full h-full bg-surface pl-4 pr-14 rounded-lg border border-line"
                    required
                    value={couponCode}
                    onChange={(ev) => {
                      setCouponCode(ev.target.value);
                    }}
                  />
                  <button
                    type="button"
                    className="button-main absolute top-1 bottom-1 right-1 px-5 rounded-lg flex items-center justify-center bg-green"
                    onClick={() => {
                      handleValidateCoupon();
                    }}
                  >
                    {t("Apply Code")}
                  </button>
                </form>
              </div>
              {!!coupon && (
                <div className="list-voucher flex items-center gap-5 flex-wrap sm:mt-7 mt-5">
                  <div className={`item  border border-line rounded-lg py-2`}>
                    <div className="top flex gap-10 justify-between px-3 pb-2 border-b border-dashed border-line">
                      <div className="left">
                        <div className="caption1">{t("Discount")}</div>
                        <div className="caption1 font-bold">
                          {coupon.value}
                          {coupon.type === "FIX" ? " KD" : "%"} OFF
                        </div>
                      </div>
                      <div className="right">
                        <div className="caption1"></div>
                      </div>
                    </div>
                    <div className="bottom gap-6 items-center flex justify-between px-3 pt-2">
                      <div className="text-button-uppercase">
                        Code: {coupon.name}
                      </div>
                      <div
                        className="button-main py-1 px-2.5 capitalize text-xs"
                        onClick={() => {}}
                      >
                        {t("Applied")}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="xl:w-1/3 xl:pl-12 w-full">
              <div className="form">
                <textarea
                  name="form-note"
                  id="form-note"
                  rows={4}
                  placeholder="Add special instructions for your order..."
                  className="caption1 py-3 px-4 bg-surface border-line rounded-md w-full"
                  value={notes}
                  onChange={(ev) => {
                    addNote(ev.target.value);
                  }}
                ></textarea>
              </div>
              <div className="checkout-block bg-surface p-6 rounded-2xl">
                <div className="heading5">{t("Order Summary")}</div>
                <div className="total-block py-5 flex justify-between border-b border-line">
                  <div className="text-title">{t("Subtotal")}</div>
                  <div className="text-title">
                    <span className="total-product">
                      {formatPrice(cartTotal, currency)}
                    </span>
                  </div>
                </div>
                {!!discount && (
                  <div className="discount-block py-5 flex justify-between border-b border-line">
                    <div className="text-title">{t("Discounts")}</div>
                    <div className="text-title">
                      <span className="discount">
                        {formatPrice(discount, currency)}
                      </span>
                    </div>
                  </div>
                )}
                {/* <div className='ship-block py-5 flex justify-between border-b border-line'>
                  <div className='text-title'>Shipping</div>
                  <div className='choose-type flex gap-12'>
                    <div className='left'>
                      <div className='type'>
                        {true ? (
                          <input
                            id='shipping'
                            type='radio'
                            name='ship'
                            disabled
                          />
                        ) : (
                          <input
                            id='shipping'
                            type='radio'
                            name='ship'
                            checked={false}
                            onChange={() => {}}
                          />
                        )}
                        <label className='pl-1' htmlFor='shipping'>
                          Free Shipping:
                        </label>
                      </div>
                      <div className='type mt-1'>
                        <input
                          id='local'
                          type='radio'
                          name='ship'
                          value={30}
                          checked={false}
                          onChange={() => {}}
                        />
                        <label
                          className='text-on-surface-variant1 pl-1'
                          htmlFor='local'
                        >
                          Local:
                        </label>
                      </div>
                      <div className='type mt-1'>
                        <input
                          id='flat'
                          type='radio'
                          name='ship'
                          value={40}
                          checked={true}
                          onChange={() => {}}
                        />
                        <label
                          className='text-on-surface-variant1 pl-1'
                          htmlFor='flat'
                        >
                          Flat Rate:
                        </label>
                      </div>
                    </div>
                    <div className='right'>
                      <div className='ship'>$0.00</div>
                      <div className='local text-on-surface-variant1 mt-1'>
                        $30.00
                      </div>
                      <div className='flat text-on-surface-variant1 mt-1'>
                        $40.00
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="total-cart-block pt-4 pb-4 flex justify-between">
                  <div className="heading5">{t("Total")}</div>
                  <div className="heading5">
                    <span className="total-cart heading5">
                      {formatPrice(cartTotal + discount, currency)}
                    </span>
                  </div>
                </div>
                <div className="block-button flex flex-col items-center gap-y-4 mt-5">
                  <div
                    className="checkout-btn button-main text-center w-full"
                    onClick={redirectToCheckout}
                  >
                    {t("Process To Checkout")}
                  </div>
                  <TabbyComponent amount={cartTotal + discount} />
                  <Link className="text-button hover-underline" to={"/shop"}>
                    {t("Continue shopping")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
