import { useSearchParams } from "react-router-dom";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Sidebar from "@/components/Product/Details/Sidebar";
import Footer from "@/components/Footer/Footer";

const ProductSidebar = () => {
  const [searchParams] = useSearchParams();
  let productId = searchParams.get("product");
  let categoryId = searchParams.get("category");

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
      </div>
      <Sidebar prodId={productId} catId={categoryId} />
      <Footer />
    </>
  );
};

export default ProductSidebar;
