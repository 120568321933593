//@ts-nocheck
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios, { endpoints } from "@/utils/axios";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";
import { useUser } from "@/context/UserContext";

const Register = () => {
  const { saveUserToLocalStorage } = useUser();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [agreed, setAgreed] = useState(false);

  const initialValues = {
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "", // Assuming you want to include this in your form handling
  };

  // Handling form submission
  const handleSubmit = async (values) => {
    setErrorMessage(null);
    if (values.password !== values.confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    // Here you can handle the form values, e.g., send them to an API

    try {
      const resp = await axios.post(endpoints.auth.register, values);

      if (resp.status === 200) {
        let userInfo = { ...resp?.data?.user, token: resp?.data?.token };

        saveUserToLocalStorage(userInfo);
        navigate(`/`);
      } else {
        setErrorMessage("Something went wrong! Please try again.");
      }
    } catch (error) {
      if (error?.includes?.("E11000")) {
        setErrorMessage("User already exists! Login or Reset your password");
      } else {
        setErrorMessage("Something went wrong! Please try again.");
      }
    }
  };

  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb
          heading="Create An Account"
          subHeading="Create An Account"
        />
      </div>
      <div className="register-block md:py-20 py-10">
        <div className="container">
          <div className="content-main flex gap-y-8 max-md:flex-col">
            <div className="left md:w-1/2 w-full lg:pr-[60px] md:pr-[40px] md:border-r border-line">
              <div className="heading4">Register</div>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {() => (
                  <Form className="md:mt-7 mt-4">
                    <div className="email">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name *"
                        required
                      />
                    </div>
                    <div className="pass mt-5">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone number *"
                        required
                      />
                    </div>
                    <div className="pass mt-5">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email address *"
                        required
                      />
                    </div>
                    <div className="pass mt-5">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password *"
                        required
                      />
                    </div>
                    <div className="confirm-pass mt-5">
                      <Field
                        className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password *"
                        required
                      />
                    </div>
                    <div className="flex items-center mt-5">
                      <Field
                        type="checkbox"
                        name="remember"
                        id="remember"
                        checked={agreed}
                        className="mr-2"
                        onClick={() => {
                          setAgreed(!agreed);
                        }}
                      />
                      <label
                        htmlFor="remember"
                        className="cursor-pointer text-secondary2"
                      >
                        I agree to the
                        <Link
                          to={"#!"}
                          className="text-black hover:underline pl-1"
                        >
                          Terms of User
                        </Link>
                      </label>
                    </div>
                    {errorMessage && (
                      <label className="cursor-pointer text-red">
                        {errorMessage}
                      </label>
                    )}
                    <div className="block-button md:mt-7 mt-4">
                      <button
                        type="submit"
                        className="button-main"
                        style={{
                          backgroundColor: agreed ? "#977052" : "#b7a999",
                        }}
                        disabled={!agreed}
                      >
                        Register
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="right md:w-1/2 w-full lg:pl-[60px] md:pl-[40px] flex items-center">
              <div className="text-content">
                <div className="heading4">Already have an account?</div>
                <div className="mt-2 text-secondary">
                  Welcome back. Sign in to access your personalized experience,
                  saved preferences, and more. We{String.raw`'re`} thrilled to
                  have you with us again!
                </div>
                <div className="block-button md:mt-7 mt-4">
                  <Link to={"/login"} className="button-main">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
